import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilState, useRecoilValue } from 'recoil';
import { depositWithUSDC } from '../App';
import { HttpClient } from "../network";
import { userAddressState, userCoinState } from '../state';
import { sleep, trackLog } from '../utils/utils';

const stripePubKey = "pk_live_51Pb1AjEG9UP4Zr2hxx7EHDZfaEWEcmBnEkKdvegQAzM91PM11TBxS1QxcxG4MZpv1GA4RpQvyyazV4RKQBm4kbq700SPMR3fFU"
const stripePromise = loadStripe(stripePubKey);

const createPaymentIntent = (amount: number): Promise<string> => {
    return new Promise((resolve) => {
        if (!amount) {
            resolve("")
            return
        }
        HttpClient.get('stripe/create-payment-intent?orderAmount=' + amount)
            .then(async (response: any) => {
                trackLog('response:', response)
                if (!response?.data) {
                    resolve("")
                    return
                }
                trackLog('setClientSecret', response.data.clientSecret)
                resolve(response.data.clientSecret)
            })
            .catch(async (e) => {
                trackLog('response err:', e)
                toast.error(e.response.data.errorMessage);
                resolve("")
            })
    })
}


export function StripePopup(props: { setIsStripePopup: (b: boolean) => void, getBalance: Function }) {
    const [amount, setAmount] = useState(0);
    const [clientSecret, setClientSecret] = useState('');
    const [isCrypto, setIsCrypto] = useState(false)
    if (amount && clientSecret) {
        return <>
            <div className="createStreamHeader" style={{ marginBottom: '10px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => {
                    if (isCrypto) {
                        setIsCrypto(false)
                    } else {
                        setClientSecret('')
                    }
                }}><img alt="back" src="/back-button.svg" style={{ width: 20, height: 20 }} /></div>
                <div style={{ flex: 1, textAlign: 'center' }}>Top Up</div>
                <img onClick={() => {
                    props.setIsStripePopup(false)
                }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
            </div>
            <Elements stripe={stripePromise} options={{ clientSecret, appearance: { theme: 'night', variables: { colorPrimary: '#37b7e6' } } }}><Checkout getBalance={props.getBalance} isCrypto={isCrypto} setIsCrypto={setIsCrypto} amount={amount} setIsStripePopup={props.setIsStripePopup} /></Elements>
        </>;
    }
    return <>
        <div className="createStreamHeader">
            <div style={{ flex: 1, textAlign: 'center' }}>Top Up</div>
            <img onClick={() => {
                props.setIsStripePopup(false)
            }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
        </div>
        <SetAmount amount={amount} setAmount={setAmount} setClientSecret={setClientSecret} />
    </>;
}

export function SetAmount(props: { amount: number, setAmount: any, setClientSecret: any }) {
    const [inputValue, setInputValue] = useState('');
    const { amount, setAmount, setClientSecret } = props
    const [creatingPaymentIntent, setCreatingPaymentIntent] = useState(false)
    const inputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleChange = (e: any) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            setInputValue(value);
        }
    };

    const handleBlur = () => {
        if (inputValue === '') {
            setAmount(0);
        } else {
            setAmount(parseFloat(inputValue));
        }
    };
    let btnStyle: any = { justifyContent: 'center', boxSizing: 'border-box', width: '220px', height: '2.55rem', fontSize: '1.2rem', marginTop: 5 }
    if (inputValue === '') {
        btnStyle.backgroundColor = '#3F3F46'
        btnStyle.color = '#71717A'
    }
    return (
        <>
            <div style={{ display: 'flex', boxSizing: 'border-box', width: '220px', gap: 10, flexDirection: 'column', alignSelf: 'center', marginTop: 12 }}>
                <div style={{ position: 'relative' }}>
                    <input
                        className="buyCoinInput"
                        placeholder="100"
                        value={inputValue}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ width: '218px', boxSizing: 'border-box', padding: '0.7rem 50px 0.7rem 24px', height: '2.55rem', fontSize: '1.2rem' }}
                        ref={inputRef}
                    />
                    <div style={{ color: '#999', position: 'absolute', left: 10, top: 8 }}>$</div>
                </div>
                <button
                    className="btn"
                    onClick={async () => {
                        if (amount) {
                            setCreatingPaymentIntent(true)
                            let sec = await createPaymentIntent(amount)
                            setCreatingPaymentIntent(false)
                            if (sec) {
                                setClientSecret(sec)
                            }
                        }
                    }}
                    style={btnStyle}
                >{creatingPaymentIntent ? <div className='spinnerNano'></div> :
                    <div style={{ padding: 2 }}>Next</div>}
                </button>
            </div>
        </>
    );
}

function Checkout(props: { getBalance: Function, isCrypto: boolean, setIsCrypto: Function, amount: number, setIsStripePopup: (b: boolean) => void }) {
    const stripe = useStripe();
    const elements = useElements();
    const [isSending, setIsSending] = useState(false);
    const [isLoadingForm, setIsLoadingForm] = useState(true)
    const [isSent, setIsSent] = useState(false)
    const userAddress = useRecoilValue(userAddressState)
    const [userCoins, setUserCoins] = useRecoilState(userCoinState)
    const [isCryptoSending, setIsCryptoSending] = useState(false)

    const prevUserCoin = useRef(userCoins);

    const handlePayClick = async () => {
        if (isSending) {
            return
        }
        if (!stripe || !elements) {
            return;
        }

        const paymentElement = elements.getElement(PaymentElement);

        if (!paymentElement) {
            toast.error('PaymentElement is not mounted');
            return;
        }

        setIsSending(true);

        const result = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: window.location.origin + '/stripe_redirect?p=' + window.location.pathname,
            },
        });

        if (result.error) {
            toast.error(result.error.message);
            setIsSending(false);
        } else {
            setIsSent(true)
        }
    };

    const handlePaymentElementChange = (event: any) => {
        trackLog('~~~', event.value.type);
        setIsSending(false)
        setIsLoadingForm(false)
    };

    const handleCryptoConfirm = async () => {
        try {
            await depositWithUSDC(props.amount, userAddress)
            for (let i = 0; i <= 999; i++) {
                try {
                    let res: any = await HttpClient.get('user/coin_balance')
                    console.log('crypto balance res', res);
                    if (res.data.coins !== userCoins) {
                        setUserCoins(res.data.coins)
                        toast.success("Successfully deposited USD")
                        props.setIsStripePopup(false)
                        return
                    }
                    await sleep(2000)
                } catch (e) {
                    toast.error('error updating coins')
                    setIsCryptoSending(false)
                }
            }
        } catch (errorMsg: any) {
            toast.error(errorMsg)
            setIsCryptoSending(false)
        }
    }

    if (isSent) {
        return <div style={{ justifyContent: "center", width: '100%', display: 'flex', padding: '50px 0', gap: 10 }}>
            <div className='spinnerNano'></div>
            <div>Updating in app balance...</div>
        </div>
    }

    if (isCryptoSending) {
        return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 30, gap: 10 }}>
            <div className='spinnerMini'></div>
            <div>Updating your new balance...</div>
        </div>
    }
    return (
        <div>
            <div style={{ display: props.isCrypto ? 'none' : 'block' }}>
                <PaymentElement onChange={handlePaymentElementChange} id="payment-element" options={{
                    layout: 'accordion',
                }} />
            </div>

            {!isLoadingForm && !props.isCrypto && <div style={{ paddingTop: 10, textDecoration: 'underline', cursor: 'pointer', fontSize: 15 }} onClick={() => {
                props.setIsCrypto(true)
            }}>Or, deposit with crypto</div>}

            {props.isCrypto && <div>
                <div style={{ marginBottom: 25, marginTop: 10 }}>
                    <div style={{}}>Step 1. Deposit <b>{props.amount}</b> Solana USDC to</div>
                    <div style={{ fontSize: 14, margin: 10 }}>{userAddress}</div>
                </div>
                <div style={{ marginBottom: 25 }}>
                    <div style={{}}>Step 2. Click "Confirm" button below after USDC is sent</div>
                </div>
            </div>}


            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button className="btn buy-coin-btn" onClick={() => {
                    if (props.isCrypto) {
                        setIsCryptoSending(true)
                        handleCryptoConfirm()
                    } else {
                        handlePayClick()
                    }
                }} style={{
                    width: '220px', boxSizing: 'border-box', justifyContent: 'center', height: '2.55rem', fontSize: '1.2rem', marginTop: '20px'
                }} >
                    {isLoadingForm ? <div className='spinnerNano'></div> : <div style={{ padding: 4 }}>Confirm</div>}
                </button>
            </div>
        </div>
    );
}

export function StripeRedirect() {
    const navigate = useNavigate()
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let path = urlParams.get('p');
        navigate(path ?? '/')
    }, [])
    return <div style={{ height: '100vh', width: '100vh' }}>
        redirectinga dfdf
    </div>
}