// detect user agent
export function isOnMobile() {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /android|iphone|ipad|ipod|windows phone/i.test(userAgent)
}

// detect chrome
export function isChrome() {
    const userAgent = navigator.userAgent;
    const isAndroidChrome = /Chrome/.test(userAgent);
    const isIosChrome = /CriOS/.test(userAgent) && /iPhone|iPad|iPod/.test(userAgent);
    return isAndroidChrome || isIosChrome;
}

export function isPwa() {
    if (navigator.userAgent === 'dev') {
        return true
    }
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone || (navigator as any).standalone;
}

export function isNumber(value?: string | number): boolean {
    return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
}

export function trackLog(...msg: any[]) {
    console.log('%ctrack', 'color: #ccc;', ...msg)
}

export function roomId2streamId(roomId: string) {
    return roomId.replace(/[^a-zA-Z0-9_-]/g, '').substring(0, 32)
}

export function filterRoomId(roomId: string) {
    return encodeURIComponent(roomId).substring(0, 32)
    // return roomId.replace(/[^a-zA-Z0-9_-]/g, '').substring(0, 32)
}
export function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
