import { Maybe } from "@web3auth/base";
import Cookies from 'js-cookie';
import { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { v4 as uuidv4 } from 'uuid';
import { ZIM, ZIMLoginConfig, ZIMSDK } from 'zego-zim-web';
import "./App.css";
// import RPC from "./ethersRPC"; // for using ethers.js

// Providers
import { SolanaWallet } from "@web3auth/solana-provider";

// Adapters
import { ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, createTransferInstruction, getAccount, getAssociatedTokenAddress } from '@solana/spl-token';
import { ComputeBudgetProgram, Connection, PublicKey, Transaction } from '@solana/web3.js';
import axios from "axios";
import mixpanel from 'mixpanel-browser';
import { ZegoExpressEngine } from "zego-express-engine-webrtc";
import { askPwaView } from "./components/askPwa";
import StreamView from "./components/streamView";
import { Stream, StreamCreationMode, UnresolvedBet } from "./model/types";
import { GUEST_DISPLAY_NAME_KEY, GUEST_ID_KEY, HttpClient, REFERER_ID_KEY, USER_TOKEN_KEY } from "./network";
import { BuyCoinResponse, LoginResponse, MessageBody, MessageBodyType, PresignUrlResponse, Response, TopUserResponse, UnresolvedBets, ZIMTokenResponse } from "./network/types";
import { RANDOM_USER_DISPLAY_NAMES, isUserLoggedIn, markLogIn } from "./utils/login";
import { isOnMobile, isPwa, trackLog } from "./utils/utils";

import { useRecoilState } from "recoil";
import { ClaimPage } from "./components/claimPage";
import { DepositPopup } from "./components/depositPopup";
import { LandingPage } from "./components/landingPage";
import { SettingsPage, WidhDraw } from "./components/settingsPage";
import { StripePopup, StripeRedirect } from "./components/stripe";
import { TwitchCallback } from "./components/twitch";
import { YoutubeCallback } from "./components/youtube";
import { defaultBetState, isLandscapeState, overallRateState, userAddressState, userCoinState, userState } from "./state";
import { FEE_PAYER_AND_DEPOSIT_ADDRESS, RPC_URL, USDCAddress, connectionType, web3auth, web3authInit } from "./wallet/web3auth";
const connection = new Connection(RPC_URL, 'confirmed');
const USDC_RENT_AMOUNT = 0.02

const ZEGO_APP_ID = 1133890363
const getZIM = () => ZIM;
getZIM().create({
  appID: ZEGO_APP_ID,
});
const zim: ZIMSDK = getZIM().getInstance();

const zegoExpressEngine = new ZegoExpressEngine(ZEGO_APP_ID, "wss://accesshub-wss.zego.im/accesshub")
zegoExpressEngine.setLogConfig({ logLevel: "warn" })

const HAS_SEEN_TUTORIAL_POPUP_KEY = 'HAS_SEEN_APP_TUTORIAL_POPUP_KEY'
const DEFAULT_PROFILE_URL = "/default_pfp.jpg"
type TimeZone = 'local' | 'utc'

export function depositWithUSDC(amount: number, userAddress: string) {
  return new Promise(async (resolve, reject) => {
    try {
      if (amount < USDC_RENT_AMOUNT) {
        reject("Deposit at least $1")
        return
      }

      const currentBalance = await getUserBalance(userAddress)
      if (amount > parseFloat(currentBalance)) {
        reject("Not enough USDC balance. Transfer more USDC to provided wallet address.")
        return
      }

      const amountAfterRent = amount - USDC_RENT_AMOUNT
      const senderPublicKey = new PublicKey(userAddress);
      const recipientPublicKey = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

      // Create a new token object for USDC (replace with the correct token mint address)
      const usdcMintAddress = new PublicKey(USDCAddress);

      // Get the associated token addresses for sender and recipient
      const senderTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        senderPublicKey);

      const recipientTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        recipientPublicKey);

      const usdcBuyAmount = Math.floor((amountAfterRent) * Math.pow(10, 6))
      console.log("!!!!!depositWithUSDC", { userAddress, usdcBuyAmount })
      // Create a transaction instruction to transfer USDC
      const transferInstruction = createTransferInstruction(
        senderTokenAccountAddress,
        recipientTokenAccountAddress,
        senderPublicKey,
        usdcBuyAmount, // Assuming USDC has 6 decimal places
        [],
        TOKEN_PROGRAM_ID
      );

      // Create a new transaction
      const transaction = new Transaction().add(transferInstruction);
      transaction.add(
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: 2000000,
        })
      )
      const { blockhash } = await connection.getLatestBlockhash();
      // Set the recent blockhash and the fee payer (sender)
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = recipientPublicKey;

      const solanaWallet = new SolanaWallet(web3auth!.provider!);
      // Sign the transaction
      const signedTransaction = await solanaWallet.signTransaction(transaction);
      // Serialize the transaction and send it to your backend
      const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

      await HttpClient.post<Response<BuyCoinResponse>>('buy/coins', {
        signedTx: serializedTransaction
      })
        .then((response) => {
          resolve(1)
        })
        .catch((e) => {
          reject(e?.response?.data?.errorMessage ?? 'unknown err')
        })
    } catch (e: any) {
      reject(e?.response?.data?.errorMessage ?? 'unknown err')
    }
  })
}

async function getUserBalance(address: string) {
  const usdcMintAddress = new PublicKey(USDCAddress);

  const associatedTokenAddress = await getAssociatedTokenAddress(
    usdcMintAddress,
    new PublicKey(address),
    false,
    TOKEN_PROGRAM_ID,
    ASSOCIATED_TOKEN_PROGRAM_ID
  );

  // Fetch the account info for the associated token address
  const accountInfo = await connection.getAccountInfo(associatedTokenAddress);

  if (!accountInfo) {
    console.log('No USDC token account found for this public key.');
    return "0";
  }

  // Decode the account info to get the balance
  const tokenAccount = await getAccount(connection, associatedTokenAddress);

  // USDC has 6 decimal places
  const newBalance = (Number(tokenAccount.amount) / Math.pow(10, 6)).toFixed(2)
  return newBalance
}

export let login = async () => {
  if (!web3auth) {
    return null
  }

  try {
    trackLog('trying login')
    const web3authProvider = await web3auth.connect();
    trackLog('web3authProvider', web3auth.connected, web3authProvider)
    if (web3auth.connected) {
      connectionType.set(web3auth)
    }
    return null
  } catch (e) {
    console.log(e)
    return null
  }
}
function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isIMLoggedIn, setIsIMLoggedIn] = useState(false);
  const [isResolver, setIsResolver] = useState(false);
  const [isCommunityMod, setIsCommunityMod] = useState(false);
  const [isShowingDisplayNamePopup, setIsShowingDisplayNamePopup] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState("");
  // local pfp for upload when updating pfp
  const [profilePicUrl, setProfilePicUrl] = useState(DEFAULT_PROFILE_URL);
  const [profileFile, setProfileFile] = useState<File>();
  const [guestId, setGuestId] = useState("");
  const [guestDisplayName, setGuestDisplayName] = useState("");
  const [refererId, setRefererId] = useState("");
  // const [userCoins, setUserCoins] = useState<number>(0);
  const [userCoins, setUserCoins] = useRecoilState(userCoinState)
  const [userPoints, setUserPoints] = useState<number>(0);
  const [streamCreationCost, setStreamCreationCost] = useState<number>(0);
  const [betStreamCreatorOrCommentatorTakeRate, setBetStreamCreatorOrCommentatorTakeRate] = useState<number>(0);
  const [giftPrice, setGiftPrice] = useState<{ [key: string]: number }>({});
  const [betCreationCost, setBetCreationCost] = useState<number>(0);
  const [betCreationTakeRate, setBetCreationTakeRate] = useState<number>(0);
  const [decisionCommentatorTakeRate, setDecisionCommentatorTakeRate] = useState<number>(0);
  const [usdcAmountToBuyCoins, setUsdcAmountToBuyCoins] = useState<number>(20);
  const [overallRate, setOverallRate] = useRecoilState(overallRateState)
  const [usdcAmountToBuyCoinsString, setUsdcAmountToBuyCoinsString] = useState<string>("20");
  const [coinsToSell, setCoinsToSell] = useState<number>(0);
  const [coinsAmountToBuy, setCoinsAmountToBuy] = useState<string>("...");
  const [ethSellFunded, setEthSellFunded] = useState<string>("0");
  const [userAddress, setUserAddress] = useRecoilState(userAddressState)
  const [userBalance, setUserBalance] = useState<string>("0");
  const [streams, setStreams] = useState<Stream[]>([]);
  const [futureStreams, setFutureStreams] = useState<Stream[]>([]);
  const [pastStreams, setPastStreams] = useState<Stream[]>([]);
  // const [topUsers, setTopUsers] = useState<TopUserResponse[]>();
  const [topPointUsers, setTopPointUsers] = useState<TopUserResponse[]>();
  const [unresolvedBets, setUnresolvedBets] = useState<UnresolvedBet[]>();
  const [streamCreationMode, setStreamCreationMode] = useState<StreamCreationMode>(StreamCreationMode.CLOSED);
  const [isShowingTutorialPopup, setIsShowingTutorialPopup] = useState(false);
  const [isShowingUSDCWithdrawPopup, setIsShowingUSDCWithdrawPopup] = useState(false);
  // const [isShowingLeaderboardPopup, setIsShowingLeaderboardPopup] = useState(false);
  const [isShowingPointLeaderboardPopup, setIsShowingPointLeaderboardPopup] = useState(false);
  const [isShowingUnresolvedBetPopup, setIsShowingUnresolvedBetPopup] = useState(false);
  const [isFetchingUnresolvedBetPopup, setIsFetchingUnresolvedBetPopup] = useState(false);
  const [isShowingReferPopup, setIsShowingReferPopup] = useState(false);
  const [isShowingUserSecret, setIsShowingUserSecret] = useState(false);
  const [userPrivateKey, setUserPrivateKey] = useState<string>("");
  const createStreamContainerRef = useRef<HTMLDivElement>(null);
  const [walletPageSendAddress, setWalletPageSendAddress] = useState("");
  const [zimToken, setZimToken] = useState("");
  const [walletPageSendAmount, setWalletPageSendAmount] = useState<number | undefined>(undefined);
  const [isSendingWalletETH, setIsSendingWalletETH] = useState(false);
  const [isSendingWalletUSDCOut, setIsSendingWalletUSDCOut] = useState(false);
  const [isSellingCoin, setIsSellingCoin] = useState(false);
  const navigate = useNavigate()
  const [hasInteracted, setHasInteracted] = useState(false)
  const [web3LoggedIn, setWeb3LoggedIn] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isShowingDepositPopup, setIsShowingDepositPopup] = useState(false)
  const [isStripePopup, setIsStripePopup] = useState(false)
  const [userPaypalId, setUserPaypalId] = useState('')
  const [withdrawSolanaAddress, setWithdrawSolanaAddress] = useState('')
  const [isWidthDrawPopup, setIsWidthDrawPopup] = useState(false)
  const [user, setUser] = useRecoilState(userState)
  const [isLandscape, setIsLandscape] = useRecoilState(isLandscapeState)
  const [defaultBet, setDefaultBet] = useRecoilState(defaultBetState)
  const handlePaymentError = (e: any) => {
    trackLog('handlePaymentError called', e.status)
    if (e.status === 456) {
      trackLog('handlePaymentError called')
      // setIsShowingDepositPopup(true)
      setIsStripePopup(true)
      return true
    }
    return false
  }
  const handleInteraction = () => {
    setHasInteracted(true);
  };

  const checkOrientation = () => {
    if (window.innerWidth > window.innerHeight) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  };

  const landscapeQuery = window.matchMedia('(orientation: landscape)');

  function handleOrientationChange(event: any) {
    if (event.matches) {
      setIsLandscape(true);
    } else {
      setIsLandscape(false);
    }
  }

  useEffect(() => {
    handleOrientationChange(landscapeQuery);
    landscapeQuery.addEventListener('change', handleOrientationChange);
    return () => {
      landscapeQuery.removeEventListener('change', handleOrientationChange);
    }
    // checkOrientation();
    // window.addEventListener('resize', checkOrientation);
    // return () => {
    //   window.removeEventListener('resize', checkOrientation);
    // };
  }, []);

  // useEffect(() => {
  //   zegoExpressEngine.on('roomStreamUpdate', handleUpdateStreamsState)
  //   return () => {
  //     zegoExpressEngine.off('roomStreamUpdate', handleUpdateStreamsState)
  //   }
  // }, [])

  // handle web3login state change
  useEffect(() => {
    const handleWeb3LoginStateChage = async () => {
      trackLog('handleWeb3LoginStateChage called', web3LoggedIn, web3auth?.connected)
      if (!isInitialized) { // prevent guest->loggedIn flash
        return
      }
      if (web3LoggedIn) {
        trackLog('web3auth', web3auth)
        const token = localStorage.getItem(USER_TOKEN_KEY)
        try {
          var userInfo: any
          if (connectionType.isWallet()) {
            userInfo = await web3auth.authenticateUser()
          } else {
            userInfo = await web3auth.getUserInfo()
          }
          trackLog('userInfo', userInfo)
          localStorage.setItem(USER_TOKEN_KEY, userInfo.idToken!)
          await loginBackend(userInfo.name, userInfo.profileImage, userInfo.email);
          setLoggedIn(true)
          handleCoinsToBuyChange(usdcAmountToBuyCoins.toString())
        } catch (e) {
          trackLog(e)
        }

      } else {
        handleCoinsToBuyChange('0')
        setupGuest()
      }
    }
    handleWeb3LoginStateChage()
  }, [isInitialized, web3LoggedIn])


  useEffect(() => {
    if (!hasInteracted) {
      const handleUserInteraction = () => handleInteraction();

      window.addEventListener('click', handleUserInteraction, { once: true });
      window.addEventListener('touchstart', handleUserInteraction, { once: true });

      return () => {
        window.removeEventListener('click', handleUserInteraction);
        window.removeEventListener('touchstart', handleUserInteraction);
      };
    }
  }, [hasInteracted]);

  useEffect(() => {
    trackLog('listen peerMessageReceived on address: ', userAddress)
    zim.on('peerMessageReceived', function (zim, { messageList, info, fromConversationID }) {
      for (const message of messageList) {
        if (!message || !message.message) {
          continue
        }
        const decodedMessageString = decodeURIComponent(message.message as string)
        const messageBody = JSON.parse(decodedMessageString) as MessageBody
        trackLog('messageBody', messageBody)

        if (messageBody.messageType == MessageBodyType.STRIPE_DEPOSIT_SUCCESS) {
          // if (message.senderUserID === "ADMIN") {
          const clientSecret = messageBody.clientSecret
          const accountBalance = messageBody.accountBalance
          trackLog('stripe deposit success', { clientSecret, accountBalance })

          // setUserBalance(accountBalance!.toFixed(2))
          if (accountBalance !== undefined) {
            trackLog('update balance', accountBalance)
            toast.success(messageBody.text);
            setUserCoins(accountBalance)
            setIsStripePopup(false)
          }
          return
          // }
        }
      }
    });
  }, [userAddress])

  const handleCoinsToBuyChange = (newUsdcAmount: string) => {
    if ((newUsdcAmount === null) && (newUsdcAmount === '') && isNaN(Number(newUsdcAmount))) {
      return
    }

    let parsedAmount = 0
    if (newUsdcAmount.endsWith(".")) {
      parsedAmount = parseFloat(newUsdcAmount.substring(0, newUsdcAmount.length - 1)) || 0
    } else {
      parsedAmount = parseFloat(newUsdcAmount) || 0
    }

    const usdcNeeded = Math.max(parsedAmount - USDC_RENT_AMOUNT, 0)
    setCoinsAmountToBuy((Math.floor(usdcNeeded * 100) / 100).toFixed(2))
    setUsdcAmountToBuyCoins(usdcNeeded)
    setUsdcAmountToBuyCoinsString(newUsdcAmount)
  }

  const updateToMaxCoinBuyAmount = () => {
    // TODO: don't hardcode 0.02 rent
    const usdcNeeded = Math.max(parseFloat(userBalance) - USDC_RENT_AMOUNT, 0)
    const usdcAmountToBuyWithTwoDecimals = (Math.floor(usdcNeeded * 100) / 100).toFixed(2)
    setCoinsAmountToBuy(usdcAmountToBuyWithTwoDecimals)
    setUsdcAmountToBuyCoins(usdcNeeded)
    setUsdcAmountToBuyCoinsString(usdcNeeded.toFixed(1) === userBalance ?
      usdcNeeded.toFixed(1) : usdcAmountToBuyWithTwoDecimals)
  }

  const handleCoinsToSellChange = (newCoinSellAmount: number) => {
    setCoinsToSell(newCoinSellAmount)
    const ethFunded = newCoinSellAmount
    setEthSellFunded(ethFunded.toFixed(2))
  }

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };


  const setupGuest = async () => {
    const refererId = searchParams.get('referer')
    if (refererId && refererId !== null) {
      localStorage.setItem(REFERER_ID_KEY, refererId)
    }

    let guestId = localStorage.getItem(GUEST_ID_KEY)
    if (guestId === null || guestId.length === 0) {
      guestId = uuidv4()
      localStorage.setItem(GUEST_ID_KEY, guestId)
    }
    setGuestId(guestId)

    let guestDisplayName = localStorage.getItem(GUEST_DISPLAY_NAME_KEY)
    if (guestDisplayName === null || guestDisplayName.length === 0) {
      guestDisplayName = RANDOM_USER_DISPLAY_NAMES[Math.floor(Math.random() * RANDOM_USER_DISPLAY_NAMES.length)]
        + "" + Math.floor(Math.random() * 1000)
      localStorage.setItem(GUEST_DISPLAY_NAME_KEY, guestDisplayName)
    }
    setGuestDisplayName(guestDisplayName)

    await HttpClient.post<Response<ZIMTokenResponse>>('guest/chat_token', {
      guestUserId: guestId
    })
      .then((response) => {
        const data = response.data
        setZimToken(data.zimToken)
        loginIM(guestId!, data.zimToken)
      })
      .catch(async (e) => {
        console.log("!!!!loginBackend guest", { e });
        toast.error("Failed to login as guest. Try again. (Reason: " + e.message + '"');
      })
  }
  const handleWeb3Connected = () => {
    connectionType.set(web3auth)
    setWeb3LoggedIn(true)
  }

  // this function should be executed only once
  const init = async () => {
    window.addEventListener('resize', handleResize);

    await web3authInit()
    if (web3auth.connected) {
      handleWeb3Connected()
    }

    // const hasSeenTutorial = localStorage.getItem(HAS_SEEN_TUTORIAL_POPUP_KEY)
    // if (!hasSeenTutorial || hasSeenTutorial !== 'true') {
    //   showTutorialPopup()
    //   localStorage.setItem(HAS_SEEN_TUTORIAL_POPUP_KEY, 'true')
    // }
    setIsInitialized(true)
  }

  useEffect(() => {
    init();
  }, []);

  const updateUserCoinBalance = async () => {
    await HttpClient.get<Response<LoginResponse>>('user/coin_balance')
      .then((response) => {
        const data = response.data

        setUserCoins(data.coins)
        setUserPoints(data.points)
      })
      .catch((e) => {
        toast.error("Failed to refresh. Try again (Reason: " + e.message + '"');
      })
  }

  const getBalance = async (address: string): Promise<string> => {
    if (!address) {
      setUserBalance("0")
      return "0"
    }

    let newBalance = await getUserBalance(address)

    setUserBalance(newBalance)
    return newBalance
  };

  const logFirstLoadIfLoggedIn = async () => {
    mixpanel.init('06afc031560e8420cc44def0765bf1de', { debug: true, track_pageview: true, persistence: 'localStorage' });
    mixpanel.track('APP_START', {})
  }

  const loginBackend = async (displayName?: string, profilePicUrl?: string, email?: string) => {
    const refererId: string | null = localStorage.getItem(REFERER_ID_KEY)
    await HttpClient.post<Response<LoginResponse>>('user/login', {
      displayName: displayName,
      profilePicUrl: profilePicUrl,
      email: email,
      refererId: refererId === null ? undefined : refererId
    })
      .then((response) => {

        const data = response.data
        if (typeof data.defaultBetSize == 'number') {
          setDefaultBet(data.defaultBetSize)
        }

        if (data.twitchStreamApiToken) {
          localStorage.setItem('twitchStreamApiToken', data.twitchStreamApiToken
          )
        }
        if (data.youtubeStreamApiToken) {
          localStorage.setItem('youtubeStreamApiToken', data.youtubeStreamApiToken
          )
        }
        setZimToken(data.zimToken)
        console.log('window.location.pathname', window.location.pathname);
        if (searchParams.get('referer')) {
          setSearchParams("")
        }
        updateLoginStatus(true)
        logFirstLoadIfLoggedIn()
        loginIM(data.address, data.zimToken)
        setRefererId(data.refererId ?? "")
        setUser({ name: data.displayName!, pic: data.profilePicUrl ?? '', address: data.address })
        setUserCoins(data.coins)
        if (data.paypalUserNameOrEmail) {
          setUserPaypalId(data.paypalUserNameOrEmail)
        }
        if (data.withdrawSolanaAddress) {
          setWithdrawSolanaAddress(data.withdrawSolanaAddress)
        }
        setUserPoints(data.points)
        setIsResolver(data.isResolver ?? false)
        setIsCommunityMod(data.isCommunityMod)
        setUserAddress(data.address)
        getBalance(data.address)
        setStreamCreationCost(data.streamCreationCost)
        setBetStreamCreatorOrCommentatorTakeRate(data.betStreamCreatorOrCommentatorTakeRate)
        if (data.giftPrice) {
          setGiftPrice(data.giftPrice)
        }
        setBetCreationCost(data.betCreationCost)
        setBetCreationTakeRate(data.betCreationTakeRate)
        setDecisionCommentatorTakeRate(data.decisionCommentatorTakeRate)
        // setOverallTakeRate(data.overallTakeRate)
        // setOverallDecisionTakeRate(data.overallDecisionTakeRate)
        setOverallRate({ take: data.overallTakeRate, decision: data.overallDecisionTakeRate })
        localStorage.removeItem(REFERER_ID_KEY)
      })
      .catch(async (e) => {
        console.log("!!!!loginBackend", { e });
        // await doLogOut()
        toast.error("Failed to login. Try again. (Reason: " + e.message + '"');
      })
  }

  const updateLoginStatus = (loggedIn: boolean) => {
    markLogIn(loggedIn)
    setLoggedIn(loggedIn)
  }

  const loginIM = async (address: string, zimToken: string) => {
    const shortAddress = address.substring(0, 32)
    console.log("loginout", { shortAddress, zimToken })
    zim.logout()
    console.log("loginIM", { shortAddress, zimToken })
    setIsIMLoggedIn(false)
    zim.login(shortAddress, {
      token: zimToken,
      isOfflineLogin: false
    } as ZIMLoginConfig)
      .then(() => {
        trackLog(address, ' logged in zim')
        console.log("Logged into zim", { shortAddress })
        setIsIMLoggedIn(true)
      })
      .catch((e: any) => {
        trackLog(address, ' failed to login', e)
        toast.error("Failed to connect. Try again.  (Reason: " + e.message + '"');
        console.log("error", e)
      })
  }

  const logout = async () => {
    if (!web3auth.connected) {
      trackLog("web3auth not initialized yet");
      return;
    }
    Cookies.remove('overlay_x', { path: '' })
    Cookies.remove('overlay_y', { path: '' })

    await doLogOut()
  };

  const doLogOut = async () => {
    try {
      await zim.logout();
      await web3auth?.logout();
      await zegoExpressEngine.logoutRoom();
      setIsIMLoggedIn(false)
    } catch (e) {
      console.log(e);
    }

    setZimToken("")
    updateLoginStatus(false)
    setUser({ name: '', pic: '', address: '' })
    setUserBalance("0")

    localStorage.clear()
    // don't show tutorial box after log out
    localStorage.setItem(HAS_SEEN_TUTORIAL_POPUP_KEY, 'true')

    window.location.href = '/'
  };

  const onPfpUpdated = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setProfilePicUrl(imageURL);
      setProfileFile(file);
    }
  }

  const createStream = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setStreamCreationMode(StreamCreationMode.EXTERNAL_LINK)
  }

  const abortingCreatingStream = () => {
    setStreamCreationMode(StreamCreationMode.CLOSED)
  }

  const closeUpdateProfileInfoPopup = () => {
    setIsShowingDisplayNamePopup(false)
    setProfilePicUrl(user.pic)
  }

  const closeDepositPopup = () => {
    setIsShowingDepositPopup(false)
    setUsdcAmountToBuyCoins(20)
    setUsdcAmountToBuyCoinsString("20")
  }

  const updateDisplayNameAndPfpOnBackend = async () => {
    if (!newDisplayName || newDisplayName.length === 0 || newDisplayName.trim().length === 0) {
      toast.error("Display name can't be empty")
      return
    }

    if (!profilePicUrl || profilePicUrl === DEFAULT_PROFILE_URL || !profileFile) {
      HttpClient.post<Response<LoginResponse>>('user/profile_info', {
        displayName: newDisplayName,
        profilePicUrl: user.pic
      })
        .then((response) => {
          const data = response.data
          console.log(data)

          if (data.profilePicUrl && data.displayName) {
            setUser({ name: data.displayName!, pic: data.profilePicUrl ?? '', address: user.address })
            closeUpdateProfileInfoPopup()
          } else {
            toast.error("Failed to update profile info. Try again");
          }
        })
        .catch((e) => {
          toast.error("Failed to update profile info. Try again (Reason: " + e.message + '"');
        })
      return
    }

    const fileName = "pfp/" + uuidv4() + profileFile.name
    await HttpClient.get<Response<PresignUrlResponse>>('user/getPresignUrl?fileName=' + fileName)
      .then((response) => {
        const data = response.data
        console.log(data)
        console.log("upload url " + data.upload_url)

        axios.put(data.upload_url, profileFile, {
          headers: {
            "Content-Type": profileFile.type
          }
        })
          .then((response) => {
            console.log(response.data)

            HttpClient.post<Response<LoginResponse>>('user/profile_info', {
              displayName: newDisplayName,
              profilePicUrl: data.upload_url.split("?")[0]
            })
              .then((response) => {
                const data = response.data
                console.log(data)

                if (data.profilePicUrl && data.displayName) {
                  setUser({ name: data.displayName!, pic: data.profilePicUrl ?? '', address: user.address })
                  closeUpdateProfileInfoPopup()
                } else {
                  toast.error("Failed to update profile info. Try again");
                }
              })
              .catch((e) => {
                toast.error("Failed to update profile info. Try again (Reason: " + e.message + '"');
              })
          })
          .catch((e) => {
            console.log(e)
            toast.error("Failed to upload profile pic (aws error). Try again (Reason: " + e.message + '"');
          })
      })
      .catch((e) => {
        toast.error("Failed to upload profile pic. Try again (Reason: " + e.message + '"');
      })
  }

  const capitalize = (s: string) => {
    if (s.toLocaleLowerCase() == "vs" || s.toLocaleLowerCase() == "v" || s.toLocaleLowerCase() == "vs.") {
      return "vs."
    }

    return s && s[0].toUpperCase() + s.slice(1)
  }

  const closeWithdrawPopup = () => {
    setIsShowingUSDCWithdrawPopup(false)
    setWalletPageSendAddress("")
    setWalletPageSendAmount(undefined)
  }

  const sendUSDCBalanceOut = async () => {
    if (!walletPageSendAmount || walletPageSendAmount <= 0) {
      toast.error("Enter a positive amount");
      return
    }

    if (!walletPageSendAddress || walletPageSendAddress === '') {
      toast.error("Enter a valid address");
      return
    }

    if (walletPageSendAmount < 10) {
      toast.error("Can only withdraw more than 10 USDC.");
      return
    }

    if (walletPageSendAmount > parseFloat(userBalance)) {
      toast.error("Not enough USDC balance. Lower your withdraw amount.");
      return
    }

    setIsSendingWalletUSDCOut(true)

    try {
      const senderPublicKey = new PublicKey(userAddress);
      const recipientPublicKey = new PublicKey(walletPageSendAddress);

      // Create a new token object for USDC (replace with the correct token mint address)
      const usdcMintAddress = new PublicKey(USDCAddress);

      // Get the associated token addresses for sender and recipient
      const senderTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        senderPublicKey);

      const recipientTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        recipientPublicKey);

      const usdcBuyAmount = Math.floor((walletPageSendAmount) * Math.pow(10, 6))
      console.log("!!!!!", { userAddress, userBalance, walletPageSendAmount, walletPageSendAddress })
      // Create a transaction instruction to transfer USDC
      const transferInstruction = createTransferInstruction(
        senderTokenAccountAddress,
        recipientTokenAccountAddress,
        senderPublicKey,
        usdcBuyAmount, // Assuming USDC has 6 decimal places
        [],
        TOKEN_PROGRAM_ID
      );

      // Create a new transaction
      const transaction = new Transaction().add(transferInstruction);
      const { blockhash } = await connection.getLatestBlockhash();
      // Set the recent blockhash and the fee payer (sender)
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

      const solanaWallet = new SolanaWallet(web3auth!.provider!);
      // Sign the transaction
      const signedTransaction = await solanaWallet.signTransaction(transaction);
      // Serialize the transaction and send it to your backend
      const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

      await HttpClient.post<Response<BuyCoinResponse>>('send/usdc', {
        signedTx: serializedTransaction
      })
        .then((response) => {
          checkForUSDCBalanceUpdate(500)
        })
        .catch((e) => {
          console.log("!!!!send USDC", { e });
          toast.error("Failed to withdraw USDC. Try again. (Reason: " + e.message + '"');
          setIsSendingWalletUSDCOut(false)
        })
    } catch (e: any) {
      console.error("failed to send USDC", { e })
      toast.error("Failed to send USDC. Try again. (Reason: " + e.message + '"');
      setIsSendingWalletUSDCOut(false)
    }
  }



  const buyCoinsWithUSDC = async () => {
    if (parseFloat(userBalance) < usdcAmountToBuyCoins) {
      toast.error("Not enough balance. Top up your account or lower your buy amount.");
      return
    }

    if (!usdcAmountToBuyCoins || usdcAmountToBuyCoins < 5 - USDC_RENT_AMOUNT) {
      toast.error("Buy at least $5");
      return
    }

    setIsSendingWalletETH(true)

    try {
      const senderPublicKey = new PublicKey(userAddress);
      const recipientPublicKey = new PublicKey(FEE_PAYER_AND_DEPOSIT_ADDRESS);

      // Create a new token object for USDC (replace with the correct token mint address)
      const usdcMintAddress = new PublicKey(USDCAddress);

      // Get the associated token addresses for sender and recipient
      const senderTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        senderPublicKey);

      const recipientTokenAccountAddress = await getAssociatedTokenAddress(
        usdcMintAddress,
        recipientPublicKey);

      const usdcBuyAmount = Math.floor((usdcAmountToBuyCoins) * Math.pow(10, 6))
      console.log("!!!!!", { userAddress, usdcBuyAmount })
      // Create a transaction instruction to transfer USDC
      const transferInstruction = createTransferInstruction(
        senderTokenAccountAddress,
        recipientTokenAccountAddress,
        senderPublicKey,
        usdcBuyAmount, // Assuming USDC has 6 decimal places
        [],
        TOKEN_PROGRAM_ID
      );

      // Create a new transaction
      const transaction = new Transaction().add(transferInstruction);
      transaction.add(
        ComputeBudgetProgram.setComputeUnitPrice({
          microLamports: 2000000,
        })
      )
      const { blockhash } = await connection.getLatestBlockhash();
      // Set the recent blockhash and the fee payer (sender)
      transaction.recentBlockhash = blockhash;
      transaction.feePayer = recipientPublicKey;

      const solanaWallet = new SolanaWallet(web3auth!.provider!);
      // Sign the transaction
      const signedTransaction = await solanaWallet.signTransaction(transaction);
      // Serialize the transaction and send it to your backend
      const serializedTransaction = signedTransaction.serialize({ requireAllSignatures: false }).toString('base64');

      await HttpClient.post<Response<BuyCoinResponse>>('buy/coins', {
        signedTx: serializedTransaction
      })
        .then((response) => {
          checkForCoinBalanceUpdate(500)
        })
        .catch((e) => {
          console.log("!!!!deposit", { e });
          toast.error(e.message)
          setIsSendingWalletETH(false)
        })
    } catch (e: any) {
      console.error("failed to send USDC", { e })
      toast.error("Failed to buy coins. Try again. (Reason: " + e.message + '"');
      setIsSendingWalletETH(false)
    }
  }

  const checkForCoinSellUpdate = async (timeout: number) => {
    const oldBalance = parseFloat(userBalance)
    const newBalance = await getBalance(userAddress)

    if (oldBalance === parseFloat(newBalance)) {
      setTimeout(() => checkForCoinSellUpdate(timeout), timeout)
    } else {
      updateUserCoinBalance()
      getBalance(userAddress)
      setIsSellingCoin(false)
      toast.success("Withdrawal success!")
    }
  }

  const checkForUSDCBalanceUpdate = async (timeout: number) => {
    const oldBalance = parseFloat(userBalance)

    const newBalance = await getBalance(userAddress)

    if (oldBalance === parseFloat(newBalance)) {
      setTimeout(() => checkForUSDCBalanceUpdate(timeout), timeout)
    } else {
      closeWithdrawPopup()
      toast.success("Withdrawal success!")
    }
  }

  const checkForCoinBalanceUpdate = async (timeout: number) => {
    await HttpClient.get<Response<LoginResponse>>('user/coin_balance')
      .then(async (response) => {
        const data = response.data

        if (data.coins !== userCoins) {
          await getBalance(userAddress)
          setUserCoins(data.coins)
          setUserPoints(data.points)
          setIsSendingWalletETH(false)

          toast.success("Successfully bought coins!")
        } else {
          setTimeout(() => checkForCoinBalanceUpdate(timeout), timeout)
        }
      })
      .catch((e) => {
        toast.error("Failed to refresh new balance. Try again (Reason: " + e.message + '"');
      })
  }

  const showWalletPrivateKey = async () => {
    const privateKey: Maybe<string> = await new SolanaWallet(web3auth!.provider!).provider.request({
      method: "solanaPrivateKey",  // This is the method to request the private key
    });

    if (privateKey) {
      setUserPrivateKey(privateKey)
    } else {
      setUserPrivateKey("Failed to fetch secret key. Refresh the page and try again.")
    }

    setIsShowingUserSecret(true)
  }

  const hideWalletPrivateKey = () => {
    setUserPrivateKey("")
    setIsShowingUserSecret(false)
  }

  const copyLink = async (link: string) => {
    await navigator.clipboard.writeText(link)
    toast.success("Link copied!")
  }

  const closePointLeaderboardPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setTopPointUsers([])
  }

  const showUnresolvedBetsPopup = async () => {
    setIsShowingUnresolvedBetPopup(true)
    setIsFetchingUnresolvedBetPopup(true)
    const response = await HttpClient.post<Response<UnresolvedBets>>('bet/all_unresolved', {})
      .then((response) => {
        const unresolvedBetsData = response.data.unresolvedBets

        unresolvedBetsData.sort((betA, betB) => betB.createdAt - betA.createdAt)
        const unresolvedBetsModel: UnresolvedBet[] = []
        for (const bet of unresolvedBetsData) {
          unresolvedBetsModel.push({
            createdAt: bet.createdAt,
            title: bet.betContent,
            url: '/stream/' + bet.roomId + '?bid=' + bet.betId
          })
        }
        setIsFetchingUnresolvedBetPopup(false)
        setUnresolvedBets([...unresolvedBetsModel])
      })
      .catch((e) => {
        console.log("!!!!showUnresolvedBets failed", { e });
        toast.error("Failed to fetch unresolved bets. Try again (Reason: " + e.message + '"');
      })
  }

  const closeUnresolvedBetsPopup = () => {
    setIsShowingUnresolvedBetPopup(false)
    setIsFetchingUnresolvedBetPopup(false)
    setUnresolvedBets([])
  }

  const showTutorialPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setIsShowingReferPopup(false)
    setIsShowingTutorialPopup(true)
  }

  const showReferPopup = () => {
    setIsShowingPointLeaderboardPopup(false)
    setIsShowingTutorialPopup(false)
    setIsShowingReferPopup(true)
  }

  const closeReferPopup = () => {
    setIsShowingReferPopup(false)
  }

  const goToSettingsPage = () => {
    navigate("/settings" + window.location.search)
  }

  const topNavView = (
    <div className="topnav">
      <div className="topnavLeftArea">
        <div className="homeIconContainer" onClick={() => { navigate("/" + window.location.search); }}>
          <img className="navbarLogo" src="/favicon.ico"></img>
        </div>
        <div className="tutorialLinks">
          <a href="https://discord.gg/kFEbEevKNf" target="_blank" >
            [<span className="highlightOnHover">Discord</span>]
          </a>
          <a onClick={goToSettingsPage} >
            [<span className="highlightOnHover">Settings</span>]
          </a>
          {
            (isCommunityMod || isResolver) && isLandscape && (
              <a className="tutorialLinks_bet" onClick={showUnresolvedBetsPopup} >
                [<span className="highlightOnHover">Bets</span>]
              </a>
            )
          }
        </div>
      </div>
      {
        loggedIn ? (
          <div className="topnavRightArea">
            <div className="topnavCoinStarRightArea">
              {/* <div className="navbarCoinCointainer" data-tooltip-id="navbar-points-explainer">
                <img className="navbarCoinImage" src="/point_star.png"></img>
                <div className="navbarCoinAmount">{(Math.round(userPoints * 10) / 10).toFixed(1)}</div>
                <Tooltip id="navbar-points-explainer" place="top" opacity="1" style={{ fontSize: "1rem", borderRadius: "0.8rem" }}>
                  <p>Earn 1 loyalty star for 1 USD spent.</p>
                </Tooltip>
              </div> */}
              <div className="navbarCoinCointainer" data-tooltip-id="navbar-coin-explainer">
                <img className="navbarCoinImage" src="/coin.png"></img>
                <div className="navbarCoinAmount">{Math.floor(userCoins * 100) / 100}</div>
                <Tooltip id="navbar-coin-explainer" place="top" opacity="1" style={{ fontSize: "1rem", borderRadius: "0.8rem" }}>
                  <p>Place correct bets to earn coins.</p>
                  <p>Create bets/streams to collect coins from fees.</p>
                </Tooltip>
              </div>
            </div>
            <button onClick={() => {
              setIsStripePopup(true)
            }} className="btn buy-coins-btn">
              Top Up
            </button>
          </div>
        ) : (
          <div className="topnavRightArea">
            <button onClick={() => {
              login()
            }} className="btn login-btn">
              Log in
            </button>
          </div>
        )
      }
    </div>
  )

  const appView = (
    <div>
      {
        isShowingTutorialPopup && (
          <>
            <div className="overlay" onClick={() => setIsShowingTutorialPopup(false)} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={() => setIsShowingTutorialPopup(false)} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                How it Works
              </div>
              {/* <div className="tutorialContentHeader">
                Bet 
              </div> */}
              <div className="tutorialContentBody">
                <div>1. Create or place bets on livestreams.</div>
                <div>2. When you bet, you earn tickets. Lower odds = more tickets.</div>
                <div>3. Payout = Total Wadgered of the Bet × Your Tickets on Winning Option / Total Winning Tickets.</div>
                <div>4. The team resolves all bets.</div>
                <div>5. Deposit or withdraw anytime on the "Deposit" page.</div>
              </div>
            </div>
          </>
        )
      }
      {
        isStripePopup && (
          <>
            <div className="overlay" onClick={() => setIsStripePopup(false)} />
            <div className="updateDisplayNameContainer popup">
              <StripePopup setIsStripePopup={setIsStripePopup} getBalance={getBalance} />
            </div>
          </>
        )
      }
      {
        isWidthDrawPopup && <>
          <div className="overlay" onClick={() => setIsWidthDrawPopup(false)} />
          <div className="popup">
            <WidhDraw userCoins={userCoins} userPaypalId={userPaypalId} setIsWidthDrawPopup={setIsWidthDrawPopup} setUserCoins={setUserCoins} withdrawSolanaAddress={withdrawSolanaAddress} setWithdrawSolanaAddress={setWithdrawSolanaAddress} setUserPaypalId={setUserPaypalId} />
          </div>
        </>
      }
      {
        isShowingDisplayNamePopup && (
          <>
            <div className="overlay" onClick={closeUpdateProfileInfoPopup} />
            <div className="updateDisplayNameContainer popup">
              <div className="createStreamHeader">
                <img onClick={closeUpdateProfileInfoPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="pfpContainer">
                <label htmlFor="pfpClicker">
                  <img className="pfpImage" src={profilePicUrl} alt="Profile Picture" />
                  <div className="uploadPfpOverlay">
                    <img className="uploadPfp" src="/upload_pfp.png" alt="Upload Profile Picture" />
                  </div>
                </label>
                <input id="pfpClicker" className="pfpUploadIcon" type="file" accept="image/*" onChange={onPfpUpdated}></input>
              </div>
              <div className="streamUserNameContainer">
                <input value={newDisplayName} onChange={e => setNewDisplayName(e.target.value)} className="inCreateStreamerUserNameInput" placeholder={user.name}></input>
              </div>
              <button className="btn create-bet-btn updateDisplayNameButton" onClick={updateDisplayNameAndPfpOnBackend}>Update</button>
            </div>
          </>
        )
      }
      {
        isShowingReferPopup && (
          <>
            <div className="overlay" onClick={closeReferPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closeReferPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="refererIntroText">You earn 1 loyalty star for every 1 USD your referee spent.</div>
              <div className="refererIntroText">Invite more friends, so you earn more stars.</div>
              <div className="refererIntroText">Your referee gets 10 loyalty stars after signing up with your referral link.</div>
              {
                isUserLoggedIn() ? (
                  <>
                    <div className="shareUrlContainer">
                      <div className="shareUrlText">{window.location.protocol + '//' + window.location.host + '?referer=' + userAddress}</div>
                      <div className="btn shareUrlContainerCopyButton"
                        onClick={() => copyLink(window.location.protocol + '//' + window.location.host + '?referer=' + userAddress)}>Copy</div>
                    </div>
                    <div className="refererFooterContainer">
                      <div>Your referral stars earned:</div>
                      <img className="referralCoinImage" src="/point_star.png"></img>
                      <div>0</div>
                    </div>
                  </>
                ) : (
                  <div className="refererIntroText">
                    (After you sign up you will see your referral link.)
                  </div>
                )
              }
            </div>
          </>
        )
      }
      {
        isShowingPointLeaderboardPopup && (
          <>
            <div className="overlay" onClick={closePointLeaderboardPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closePointLeaderboardPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                Leaderboard
              </div>
              <div className="tutorialContentBody">
                {
                  (!topPointUsers || topPointUsers.length === 0) ? (
                    <div className="leaderboardLoading">Loading...</div>
                  ) : (
                    <div>
                      {
                        topPointUsers.map((user, index) => (
                          <div className="leaderboardItem">
                            <div>{(index + 1) + ". " + user.displayName + "(" + user.userId.substring(0, 2) + "..." + user.userId.substring(39) + ")"}</div>
                            <div className="leaderboardCoinContainer">
                              <img className="leaderboardCoinImage" src="/point_star.png"></img>
                              <div>{user.points}</div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </>
        )
      }
      {
        isShowingUnresolvedBetPopup && (
          <>
            <div className="overlay" onClick={closeUnresolvedBetsPopup} />
            <div className="tutorialContainer">
              <div className="createStreamHeader">
                <img onClick={closeUnresolvedBetsPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="tutorialHowToPlay">
                Unresolved Bets
              </div>
              <div className="tutorialContentBody">
                {
                  (isFetchingUnresolvedBetPopup) ? (
                    <div>Loading...</div>
                  ) : (
                    (unresolvedBets && unresolvedBets.length > 0) ? (
                      unresolvedBets?.map((bet) => (
                        <div className="leaderboardItem">
                          <a href={bet.url}>{bet.title}</a>
                        </div>
                      ))
                    ) : (
                      <div>All bets are resolved. Yay!</div>
                    )
                  )
                }
              </div>
            </div>
          </>
        )
      }
      {
        isShowingDepositPopup && <DepositPopup
          userBalance={userBalance}
          closeDepositPopup={closeDepositPopup}
          isSendingWalletETH={isSendingWalletETH}
          updateToMaxCoinBuyAmount={updateToMaxCoinBuyAmount}
          usdcAmountToBuyCoinsString={usdcAmountToBuyCoinsString}
          handleCoinsToBuyChange={handleCoinsToBuyChange}
          buyCoinsWithUSDC={buyCoinsWithUSDC}
        />
      }
      {
        isShowingUSDCWithdrawPopup && (
          <>
            <div className="overlay" onClick={closeWithdrawPopup} />
            <div className="withdrawUSDCPopupContainer popup" ref={createStreamContainerRef}>
              <div className="createStreamHeader">
                <img onClick={closeWithdrawPopup} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="streamUserNameContainer">
                <div className="streamUserNameTitle">Withdraw</div>
                <div>Amount: <input className="withdrawPopupInput" value={walletPageSendAmount} onChange={e => setWalletPageSendAmount(Number(e.target.value))} type="number" placeholder="amount"></input></div>
                <div>Address: <input className="withdrawPopupInput" value={walletPageSendAddress} onChange={e => setWalletPageSendAddress(e.target.value)} type="string" placeholder="address"></input></div>
              </div>
              <button disabled={isSendingWalletUSDCOut} className="btn create-bet-btn updateDisplayNameButton" onClick={sendUSDCBalanceOut}>
                {isSendingWalletUSDCOut ? "Sending..." : "Send"}
              </button>
            </div>
          </>
        )
      }
      {
        isShowingUserSecret && (
          <>
            <div className="overlay" onClick={hideWalletPrivateKey} />
            <div className="secretKeyPopupContainer popup" ref={createStreamContainerRef}>
              <div className="createStreamHeader">
                <img onClick={hideWalletPrivateKey} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
              </div>
              <div className="secretKeyContainer">
                {userPrivateKey}
              </div>
              <div className="secretKeyContainerFooter">
                *Never show secret to anyone else.
              </div>
            </div>
          </>
        )
      }
      {topNavView}
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path={"/settings"} element={<SettingsPage
          logout={logout}
          userPaypalId={userPaypalId}
          withdrawSolanaAddress={withdrawSolanaAddress}
          loggedIn={loggedIn}
          setIsStripePopup={setIsStripePopup}
          setUserCoins={setUserCoins}
          userCoins={userCoins}
          setIsWidthDrawPopup={setIsWidthDrawPopup}
          setIsShowingDisplayNamePopup={setIsShowingDisplayNamePopup} />} />
        <Route path={"/stripe_redirect"} element={<StripeRedirect />} />
        <Route path={"/claim"} element={<ClaimPage login={login} setUserCoins={setUserCoins} />} />
        <Route path="/twitch/callback" element={TwitchCallback} />
        <Route path="/youtube/callback" element={YoutubeCallback} />
        <Route path="/stream/:id" element={<StreamView zim={zim} isIMLoggedIn={isIMLoggedIn}
          handlePaymentError={handlePaymentError}
          web3auth={web3auth}
          hasInteracted={hasInteracted}
          zegoExpressEngine={zegoExpressEngine} zimToken={zimToken}
          userCoins={userCoins} setUserCoins={setUserCoins} setUserPoints={setUserPoints} displayName={user.name}
          profilePicUrl={user.pic} login={login} userAddress={userAddress} guestId={guestId}
          isCommunityMod={isCommunityMod}
          isResolver={isResolver} betCreationTakeRate={betCreationTakeRate} betCreationCost={betCreationCost}
          decisionCommentatorTakeRate={decisionCommentatorTakeRate}
          giftPrice={giftPrice}
          updateUserCoinBalance={updateUserCoinBalance}
          mixpanel={mixpanel}
          connection={connection}
          screenWidth={screenWidth} />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>

      <ToastContainer position="top-center" autoClose={2500} />
    </div>
  );
  return (isOnMobile() && !isPwa()) ? askPwaView() : appView
}

export default function Root() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}


export function AskRotate() {
  return <div style={{
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 25,
    zIndex: 1999,
    position: 'fixed',
    left: 0,
    top: 0,
    background: 'rgb(0,0,0,0.8)'
  }}>
    <div style={{ width: '70%', height: 'auto', padding: '30px 0px', backgroundColor: 'white', borderRadius: 20, color: 'black', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 10 }}>
      <img src="/rotate_device_hint.png" style={{ width: 120, height: 'auto' }} />
      <div style={{ fontWeight: 'bold' }}>Rotate your device</div>
      <div style={{ fontSize: 15, width: 200, textAlign: 'center' }}>
        Please rotate your device to landscape mode for the best viewing experience.
      </div>
    </div>
  </div >
}