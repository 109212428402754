import { atom } from 'recoil';

export const userState = atom({
    key: 'user',
    default: {
        name: '',
        pic: "/default_pfp.jpg",
        address: ''
    }
});

export const overlayBetState = atom<{ betId: string, placed: { name: string, pic: string, index: number }[] }>({
    key: 'overlayUserBetState',
    default: { betId: '', placed: [] }
});


export const w3loginState = atom({
    key: 'w3loginState',
    default: false
});

export const streamRectState = atom({
    key: 'streamerRectState',
    default: { width: 0, height: 0 }
})

export const newResolvedBetsState = atom<string[]>({
    key: 'resolvedBetsState',
    default: []
})

export const isLandscapeState = atom({
    key: 'isLandscape',
    default: false
})

export const isCaptureOverlayState = atom({
    key: 'isCaptureOverlay',
    default: false
})

export const userAddressState = atom({
    key: 'userAddress',
    default: '',
})

export const userCoinState = atom({
    key: 'userCoin',
    default: 0,
})

export const defaultBetState = atom({
    key: 'defaultBet',
    default: 0,
})

export const overallRateState = atom({
    key: 'ovarallRate',
    default: {
        take: 0,
        decision: 0
    }
})
