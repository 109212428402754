import { useEffect } from "react"
import { Stream } from "../model/types"
import { SelectedChannel } from "../network/types"
import { trackLog } from "../utils/utils"
import { getStreamType } from "./streamView"

export const updateStreamState = (stream: Stream, updatedRoomId: string, updateType: string) => {
    if (updatedRoomId.substring(0, 32) !== stream.id.substring(0, 32)) {
        return
    }
    if (updateType == 'ADD') {
        stream.isStreamingNatively = true
    } else if (updateType == 'DELETE') {
        stream.isStreamingNatively = false
    }
}

export const StreamFramePreview = (props: { stream: Stream }) => {
    const { stream } = props
    const id = stream.newStreamId ?? stream.id
    const streamType = getStreamType(id)
    if (streamType === SelectedChannel.KICK) {
        return <iframe className="previewStreamPlayer" src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=false`} allowFullScreen={false} />
    } else if (streamType === SelectedChannel.YOUTUBE) {
        return <iframe className="previewStreamPlayer" src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=0`} allow="autoplay" allowFullScreen={false} />
    } else if (streamType === SelectedChannel.TWITCH) {
        return <div className="previewStreamPlayer"><iframe className="previewStreamPlayer" src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen={false}> </iframe></div>
    } else if (streamType === SelectedChannel.STREAMED) {
        return stream.streamPreviewThumbnail ?
            <div className="previewStreamPlayer" >
                <div style={{ backgroundImage: `url(${stream.streamPreviewThumbnail})`, backgroundPosition: "center", backgroundSize: "cover", width: "100%", height: '100%' }}>
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', fontSize: '1em', padding: 3 }}>{stream.streamTitle}</div>
                </div>
            </div> :
            <div className="previewStreamPlayer">
                <div style={{ display: "flex", gap: 15, flexDirection: "column", alignItems: "center" }}>
                    <div>{stream.streamTitle}</div>
                </div>
            </div>
    } else if (streamType === SelectedChannel.TWITTER) {
        return <div className="previewStreamPlayer">
            <TwitterPlayer url={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`} />
        </div>
    } else if (streamType === SelectedChannel.IN_APP) {
        return <div className="previewStreamPlayer">{previewStreamerBox(stream)}</div>
    }

    return <div className="previewStreamPlayer">Can't find this stream:{id}</div>
}

function previewStreamerBox(stream: Stream) {
    let stateInfo = stream.streamerDisplayName + ' is offline'
    let liveLabel = null
    if (stream.isStreamingNatively) {
        stateInfo = stream.streamerDisplayName + ' is LIVE'
        liveLabel = <div style={{ position: 'absolute', right: 20, top: 20, background: 'red', padding: '3px 7px', color: 'white', fontWeight: 'bold', borderRadius: 5 }}>LIVE</div>
    }
    return <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {liveLabel}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, alignItems: 'center' }}>
            <div style={{ fontSize: '1.5rem' }}>{stream.streamTitle}</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem' }}>
                <img src={stream.streamerProfilePicUrl} style={{ width: '1.5rem', height: '1.5rem', borderRadius: '50%' }}></img>
                <div>{stateInfo}</div>
            </div>
        </div>
    </div>
}

export const StreamFrame = (props: {
    hasInteracted: boolean,
    roomId: string,
    id: string,
    operationPanel: (id: string) => JSX.Element
}) => {
    const { id, operationPanel } = props
    const streamType = getStreamType(id)
    if (streamType === SelectedChannel.KICK) {
        return <iframe className="streamPlayer" src={`https://player.kick.com/${id!.split("~")[1]}?autoplay=true}`} allowFullScreen={false} />
    } else if (streamType === SelectedChannel.YOUTUBE) {
        return <YoutubePlayer id={id!.split("~")[1]} />
    } else if (streamType === SelectedChannel.TWITCH) {
        return <iframe className="streamPlayer" src={`https://player.twitch.tv/?channel=${id!.split("~")[1]}&parent=thelive.bet`} allowFullScreen={false}> </iframe>
    } else if (streamType === SelectedChannel.STREAMED) {
        return <iframe className="streamPlayer"
            allow="encrypted-media; picture-in-picture;"
            allowFullScreen={false}
            // sandbox="allow-same-origin allow-scripts"
            src={`https://embedme.top/embed/${id!.split("~")[1]}`}></iframe>
    } else if (streamType === SelectedChannel.TWITTER) {
        return <div className="streamPlayer" ><TwitterPlayer url={`https://twitter.com/${id!.split("~")[1]}/status/${id!.split("~")[2]}`} /></div>
    } else if (streamType === SelectedChannel.IN_APP) {
        return operationPanel(id)
    }

    return <div className="streamPlayer"><div className="spinner"></div></div>
}

// old Youtube player:
// <iframe className="streamPlayer" src={`https://www.youtube.com/embed/${id!.split("~")[1]}?autoplay=1}`} allow="autoplay" allowFullScreen={false} />

let win = window as any

function YoutubePlayer(props: { id: string }) {
    // const player = useRef(null)
    const loadVideo = () => {

        new win.YT.Player(`youtube-player-${props.id}`, {
            videoId: props.id,
            events: {
                'onReady': (event: any) => {
                    event.target.setVolume(50);
                }
            }
        })
    }
    useEffect(() => {
        if (!win.YT) {
            trackLog('init YT')
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';

            win.onYouTubeIframeAPIReady = () => {
                loadVideo()
            }
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

        } else {
            loadVideo();
        }
    }, [])


    return <div className="streamPlayer"><div id={`youtube-player-${props.id}`} style={{ width: '100%', height: '100%' }}></ div></div>
}

function TwitterPlayer(props: { url: string }) {
    useEffect(() => {
        if ((window as any).twttr?.widgets) {
            (window as any).twttr.widgets.load();
            console.log("~~~reload page");

            return;
        }

        const existingScript = document.querySelector("script[src='https://platform.twitter.com/widgets.js']");
        if (!existingScript) {
            const script = document.createElement("script");
            script.src = "https://platform.twitter.com/widgets.js";
            script.async = true;
            script.onload = () => {
                (window as any).twttr?.widgets?.load();
            };
            document.body.appendChild(script);
        } else {
            (window as any).twttr?.widgets?.load();
        }
    }, [])
    return <blockquote data-align="center" data-media-max-width={1500} className="twitter-tweet" data-lang="en" data-dnt="true" data-theme="light" >
        <a href={props.url}></a>
    </blockquote>
}