import axios from 'axios'
import html2canvas from 'html2canvas'
import Cookies from 'js-cookie'
import { GripHorizontal } from 'lucide-react'
import { useEffect, useRef, useState } from "react"
import { toast } from 'react-toastify'
import { useRecoilState, useRecoilValue } from "recoil"
import { login } from "../App"
import { Bet } from "../model/types"
import { HttpClient } from '../network'
import { defaultBetState, isCaptureOverlayState, newResolvedBetsState, overallRateState, overlayBetState, streamRectState, userState } from '../state'
import { isPwa } from '../utils/utils'
import './overlayBet.css'
import { getAfterBetWinningCoinsAmount } from './streamView'

type calWin = (selectedBet: Bet, amount: number, optionIndex: number,) => number

const demoBetId = '5b906367-677c-4154-ad02-16cfa2e2bdd2'

export function OverlayBetContainer({ newBets, overlayPlaceBet, trendingBet, streamContainerRef, allBets, isPrview }: {
    newBets: (Bet & { added: number })[]
    overlayPlaceBet?: (betId: string, optionIndex: number) => Promise<any>
    trendingBet?: Bet
    streamContainerRef: any
    allBets: Bet[]
    isPrview?: boolean
}) {
    const newResolvedBets = useRecoilValue(newResolvedBetsState)
    const [isCaptureOverlay, setIsCaptureOverlay] = useRecoilState(isCaptureOverlayState)
    const streamRect = useRecoilValue(streamRectState)
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const dragStartPos = useRef({ x: 0, y: 0 });
    const overlayRef = useRef<HTMLDivElement | null>(null)
    const [overlayBetId, setOverlayBetId] = useState('')
    const [initOpacity, setInitOpacity] = useState(0)
    if (!overlayPlaceBet) {
        overlayPlaceBet = async (betId: string, optionIndex: number) => true
    }
    useEffect(() => {
        console.log('overlay called');
        if (!overlayRef) {
            return
        }

        const rePositionOverlay = () => {
            if (overlayRef.current && streamContainerRef.current) {
                const _streamRect = streamContainerRef.current.getBoundingClientRect();
                const rect = overlayRef.current.getBoundingClientRect();
                if (isPrview) {
                    if (isPwa()) {
                        setPosition({ x: _streamRect.width / 2 - (rect.width * 2) / 2, y: _streamRect.height - rect.height - 30 })
                        return
                    }
                    setPosition({ x: _streamRect.width / 2 - (rect.width * 9) / 17, y: _streamRect.height - rect.height - 30 })
                    return
                }
                const newPst = { x: _streamRect.width / 2 - rect.width / 2, y: _streamRect.height - rect.height - 0 }
                console.log('overlay resize', newPst);

                setPosition(newPst)
                Cookies.remove('overlay_x', { path: '' })
                Cookies.remove('overlay_y', { path: '' })
            }
        }// w*9/2
        setTimeout(() => {
            // console.log('overlay st');
            // if (!Cookies.get('overlay_x') || !Cookies.get('overlay_y')) {
            //     rePositionOverlay()
            // }
            setInitOpacity(1)
        }, 1000);

        window.addEventListener('resize', rePositionOverlay);
        return () => {
            window.removeEventListener('resize', rePositionOverlay);
        };


    }, [])

    const handleMouseDown = (e: any) => {
        console.log('handleMouseDown');

        setIsDragging(true);
        dragStartPos.current = {
            x: e.clientX - position.x,
            y: e.clientY - position.y
        };
    };

    const handleMouseMove = (e: any) => {
        if (!isDragging) return;
        if (!overlayRef.current) {
            return
        }
        const rect = overlayRef.current.getBoundingClientRect()
        let x = e.clientX - dragStartPos.current.x
        let y = e.clientY - dragStartPos.current.y
        if (x < 0 || (x + rect.width) > streamRect.width || y < 0 || (y + rect.height) > streamRect.height) {
            return
        }

        setPosition({
            x,
            y
        });
    };

    const handleMouseUp = (e: any) => {
        console.log('handleMouseUp');

        Cookies.set('overlay_x', (e.clientX - dragStartPos.current.x).toString(), { expires: 7, path: '' })
        Cookies.set('overlay_y', (e.clientY - dragStartPos.current.y).toString(), { expires: 7, path: '' })
        setIsDragging(false);
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        console.log('handleTouchStart');

        setIsDragging(true);
        const touch = e.touches[0];
        dragStartPos.current = {
            x: touch.clientX - position.x,
            y: touch.clientY - position.y
        };
    };

    const handleTouchMove = (e: TouchEvent) => {
        if (!isDragging) return;
        if (!overlayRef.current) {
            return;
        }
        const rect = overlayRef.current.getBoundingClientRect();
        const touch = e.touches[0];
        let x = touch.clientX - dragStartPos.current.x;
        let y = touch.clientY - dragStartPos.current.y;

        if (x < 0 || (x + rect.width) > streamRect.width || y < 0 || (y + rect.height) > streamRect.height) {
            return;
        }

        setPosition({
            x,
            y
        });

        e.preventDefault();
    };

    const handleTouchEnd = (e: TouchEvent) => {
        console.log('handleTouchEnd');

        const touch = e.changedTouches[0];
        Cookies.set('overlay_x', (touch.clientX - dragStartPos.current.x).toString(), { expires: 7, path: '' });
        Cookies.set('overlay_y', (touch.clientY - dragStartPos.current.y).toString(), { expires: 7, path: '' });
        setIsDragging(false);
    };


    useEffect(() => {
        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            window.addEventListener('touchmove', handleTouchMove, { passive: false });
            window.addEventListener('touchend', handleTouchEnd);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isDragging]);

    useEffect(() => {
        if (isCaptureOverlay) {
            console.log('isCaptureOverlay');
            if (overlayRef.current) {
                html2canvas(overlayRef.current).then((canvas) => {
                    canvas.toBlob(async (blob) => {
                        try {
                            // const imgData = canvas.toDataURL('image/png');
                            let sign: any = await HttpClient.get('bet/getMediaCardUrl?bid=' + overlayBetId)
                            console.log('share sign', sign);
                            let res = await axios.put(sign.data.upload_url, blob, {
                                headers: {
                                    "Content-Type": 'image/png'
                                }
                            })
                            console.log('share  res', res);
                            let imgUrl = sign.data.upload_url.split("?")[0]
                            console.log('share png:', imgUrl);
                            const currentUrl = window.location.href;
                            const url = new URL(currentUrl);
                            const params = new URLSearchParams(url.search);
                            params.set('bid', overlayBetId);
                            const newUrl = `${url.origin}${url.pathname}?${params.toString()}`;
                            let xUrl = `https://x.com/intent/post?text=${encodeURIComponent('Come bet with me on Live Bet')}&url=${encodeURIComponent(newUrl)}`
                            window.open(xUrl, '_blank');

                        } catch (e: any) {
                            toast.error(e.response.data.errorMessage ?? 'unknown err');
                        }
                        // const link = document.createElement('a');
                        // link.href = imgData;
                        // link.download = 'screenshot.png';
                        // link.click();
                        setIsCaptureOverlay(false)
                    }, 'image/png')
                })

            }
        }
    }, [isCaptureOverlay])
    let overlayBody = <></>
    let resolvedBet
    if (newResolvedBets.length) {
        let betId = newResolvedBets[newResolvedBets.length - 1]
        resolvedBet = allBets.find(bet => bet.id === betId)
    }
    let liveBet = newBets.find(bet => bet.added > (Date.now() - 10_000))

    if (resolvedBet) {
        overlayBody = <OverlayResolve isPreview={isPrview} setPosition={setPosition} setOverlayBetId={setOverlayBetId} streamContainerRef={streamContainerRef} key={resolvedBet.id} bet={resolvedBet} overlayPlaceBet={overlayPlaceBet} />
    } else if (liveBet) {
        let updatedliveBet = allBets.find(bet => (liveBet?.id && bet.id == liveBet.id)) ?? liveBet
        updatedliveBet.added = liveBet.added
        overlayBody = <OverlayBet isPreview={isPrview} setPosition={setPosition} setOverlayBetId={setOverlayBetId} streamContainerRef={streamContainerRef} key={liveBet.id} bet={updatedliveBet} overlayPlaceBet={overlayPlaceBet} />
    }
    else if (trendingBet) {
        if (trendingBet.resolvedOption >= 0) {
            overlayBody = <OverlayResolve isPreview={isPrview} setPosition={setPosition} setOverlayBetId={setOverlayBetId} streamContainerRef={streamContainerRef} key={trendingBet.id} bet={trendingBet} overlayPlaceBet={overlayPlaceBet} />
        } else {
            overlayBody = <OverlayBet isPreview={isPrview} setPosition={setPosition} setOverlayBetId={setOverlayBetId} streamContainerRef={streamContainerRef} key={trendingBet.id} bet={trendingBet} overlayPlaceBet={overlayPlaceBet} />
        }
    }
    if (isPrview) {
        return <div ref={overlayRef} className="overlayBetPreviewWrap" style={{
            opacity: initOpacity
        }}> <div className='overlayBetPreview'>
                {overlayBody}
            </div>
        </div>
    }
    return <div ref={overlayRef} className="overlayTrendingBetWrap" style={{
        transform: `translate(${position.x}px, ${position.y}px) `,
        opacity: initOpacity
    }}><div style={{ position: 'absolute', left: 0, top: -10, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div style={{ padding: 5 }} onMouseDown={handleMouseDown}
                onTouchStart={handleTouchStart}>
                <GripHorizontal size={16} style={{ border: '1px solid #333', padding: '0px 5px', borderRadius: 10, background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0) 20%, rgb(0, 0, 0) 70%)', cursor: 'move' }} />
            </div>
        </div>
        {overlayBody}
    </div>
}

const bubbleItv = 800 //ms
function OverlayBet({ bet, overlayPlaceBet, streamContainerRef, setOverlayBetId, setPosition, isPreview }: {
    bet: Bet & { added?: number },
    overlayPlaceBet: (betId: string, optionIndex: number) => Promise<any>
    streamContainerRef: any
    setOverlayBetId: Function
    setPosition: Function
    isPreview?: boolean
}) {
    const [countDown, setCountDown] = useState(10)
    // const [userBets, setUserBets] = useState<number[]>([])
    const [overlayBet, setOverlayBet] = useRecoilState(overlayBetState)
    const user = useRecoilValue(userState)
    const [bubbles, setBubbles] = useState<{ [k: number]: { name: string, pic: string, index: number, displayAfter: number }[] }>({})
    const [liveBubbles, setLiveBubbles] = useState<{ [k: number]: { name: string, pic: string, index: number, displayAfter: number }[] }>({})
    const [lastDisplayAfter, setLastDisplayAfter] = useState<{ [k: number]: number }>({ 0: 0, 1: 0, 2: 0, 3: 0 })

    const innerOverlayRef = useRef<HTMLDivElement | null>(null)
    const streamRect = useRecoilValue(streamRectState)
    const defaultBetSize = useRecoilValue(defaultBetState)
    const overallRate = useRecoilValue(overallRateState)

    useEffect(() => {
        if (innerOverlayRef.current) {
            console.log('overlay innerOverlayRef');
            const _streamRect = streamContainerRef.current.getBoundingClientRect();
            const rect = innerOverlayRef.current.getBoundingClientRect();
            if (isPreview) {
                if (isPwa()) {
                    setPosition({ x: _streamRect.width / 2 - (rect.width * 2) / 2, y: _streamRect.height - rect.height - 30 })
                    return
                }
                setPosition({ x: _streamRect.width / 2 - (rect.width * 9) / 17, y: _streamRect.height - rect.height - 20 })
                return
            }
            const newPst = { x: _streamRect.width / 2 - rect.width / 2, y: _streamRect.height - rect.height - 0 }
            let x = Cookies.get('overlay_x')
            let y = Cookies.get('overlay_y')
            if (!x || !y) {
                console.log('overlay no cookie', newPst, rect);
                setPosition(newPst)
            } else {
                console.log('overlay impl cookie');

                setPosition({ x: parseInt(x), y: parseInt(y) })
            }
            // overlayRef.current.style.opacity = '1'
        }
    }, [innerOverlayRef])

    useEffect(() => {
        setOverlayBetId(bet.id)
    }, [])
    useEffect(() => {
        return () => {
            setOverlayBet({ betId: '', placed: [] })
        };
    }, [])

    useEffect(() => {
        setOverlayBet({ betId: bet.id, placed: [] })
    }, [bet.id])
    useEffect(() => {
        if (countDown < 0) {
            return
        }
        let itv = setInterval(() => {
            setCountDown(countDown => countDown - 1)
        }, 1000);
        return () => clearInterval(itv);
    }, [bet.id, countDown])
    useEffect(() => {
        if (overlayBet.betId !== bet.id) {
            return
        }
        console.log('testoverlay', overlayBet.placed.length);
        if (overlayBet.placed.length > 0) {
            let newPlaced = overlayBet.placed[overlayBet.placed.length - 1]
            let newBubbles = { ...bubbles }
            if (!newBubbles[newPlaced.index]) {
                newBubbles[newPlaced.index] = []
            }
            let optionInex = newPlaced.index
            let optionBubbles = newBubbles[newPlaced.index]
            let newLastDisplayAfter = { ...lastDisplayAfter }
            if (!optionBubbles.length) {
                optionBubbles.push({ ...newPlaced, displayAfter: Date.now() })
                setBubbles(newBubbles)
                newLastDisplayAfter[optionInex] = Date.now()
                setLastDisplayAfter(newLastDisplayAfter)
            } else {
                let lastBubble = optionBubbles[optionBubbles.length - 1]
                let itv = Date.now() - newLastDisplayAfter[optionInex]

                if (itv > bubbleItv) {
                    optionBubbles.push({ ...newPlaced, displayAfter: Date.now() })
                    newLastDisplayAfter[optionInex] = Date.now()
                    setLastDisplayAfter(newLastDisplayAfter)
                    setBubbles(newBubbles)
                } else {
                    let itvIncreas = bubbleItv - itv
                    console.log('itv~~~ itvIncreas', itvIncreas);
                    newLastDisplayAfter[optionInex] += bubbleItv
                    setLastDisplayAfter(newLastDisplayAfter)
                    console.log('setTimeoutsto set', itvIncreas)
                    let sto = setTimeout(() => {
                        console.log('setTimeoutsto get', itvIncreas);
                        optionBubbles.push({ ...newPlaced, displayAfter: newLastDisplayAfter[optionInex] })
                        setBubbles(newBubbles)
                    }, itvIncreas);
                    return () => {
                        console.log('setTimeoutsto cleared')
                        // clearTimeout(sto)
                    }
                }
            }
        }
    }, [overlayBet.betId, overlayBet.placed.length])


    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference * (1 - countDown / 9);
    const _className = bet.added ? 'overlayTrendingBet betAnimation' : 'overlayTrendingBet betNoAnimation'
    return <div ref={innerOverlayRef} className={_className}><div id={"betid~" + bet.id} key={"betid~" + bet.id}>
        <div className='overlayBetTitle'>
            <div>{bet.added ? '🆕' : '🔥'}</div>
            <div className='overlayBetTitleText' style={{ paddingRight: 10 }}>{bet.title}</div>
            <div className="overlayBetInfo">
                {(countDown >= 0 && bet.added !== undefined) ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <svg width="30" height="30" viewBox="0 0 120 120">
                            <circle
                                cx="60"
                                cy="60"
                                r={radius}
                                fill="none"
                                stroke="#e0e0e0"
                                strokeWidth="10"
                            />
                            <circle
                                cx="60"
                                cy="60"
                                r={radius}
                                fill="none"
                                stroke="#007bff"
                                strokeWidth="10"
                                strokeDasharray={circumference}
                                strokeDashoffset={strokeDashoffset}
                                strokeLinecap="round"
                                transform="rotate(-90 60 60)"
                            />
                            <text
                                x="60"
                                y="60"
                                textAnchor="middle"
                                dy=".3em"
                                fontSize="66"
                                fill="white"
                            >
                            </text>
                        </svg>
                    </div> :
                    <div style={{ color: '#eee' }}>${Math.ceil(bet.optionTVL.reduce((acc, num) => acc + num, 0))} wagered</div>
                }
            </div>
        </div>
        {
            <div className="overlayBetBody">
                {
                    bet.options.map(
                        (option, optionIndex) => {
                            return <div
                                onClick={() => {
                                    if (!user?.name) {
                                        login()
                                        return
                                    }
                                    setOverlayBet(prev => {
                                        return { betId: prev.betId, placed: [...prev.placed, { index: optionIndex, name: user.name, pic: user.pic }] }
                                    })
                                    overlayPlaceBet(bet.id, optionIndex)
                                }}
                                className={'overlayBetOption overlayBetOption' + optionIndex}
                                style={{ userSelect: 'none' }}
                                key={bet.id + optionIndex}>
                                {
                                    bubbles[optionIndex] && bubbles[optionIndex].map((placed, i) => {
                                        return <div key={bet.id + i.toString()} className="betAnimationPop">
                                            <img alt={placed.name} src={placed.pic} className="avatarSmall" />
                                            {placed.name} wagered
                                        </div>
                                    })
                                }
                                {/* {liveUserBet.key > -1 && liveUserBet.placed.index === optionIndex && <div key={liveUserBet.key} className="betAnimationPop">
                                        <img alt={liveUserBet.placed.name} src={liveUserBet.placed.pic} className="avatarSmall" />
                                        {liveUserBet.placed.name} wagered
                                    </div>} */}

                                <div className='overlayBetOptionText'>{option}</div>
                                <div>
                                    <div style={{ textAlign: 'center', fontSize: 13 }}>(Bet ${defaultBetSize})</div>
                                    <div className='overlayBetOptionSub'>Win ${getAfterBetWinningCoinsAmount(bet, defaultBetSize, optionIndex, overallRate).toFixed(2)}</div>
                                </div>
                            </div>
                        }
                    )
                }
            </div>
        }
    </div></div>
}



function OverlayResolve({ bet, overlayPlaceBet, streamContainerRef, setOverlayBetId, setPosition, isPreview }: {
    bet: Bet & { added?: number },
    overlayPlaceBet: (betId: string, optionIndex: number) => Promise<any>
    streamContainerRef: any
    setOverlayBetId: Function
    setPosition: Function
    isPreview?: boolean
}) {
    const innerOverlayRef = useRef<HTMLDivElement | null>(null)
    const streamRect = useRecoilValue(streamRectState)

    useEffect(() => {
        console.log('overlay useEffect');
        if (innerOverlayRef.current) {
            console.log('overlay innerOverlayRef');
            const _streamRect = streamContainerRef.current.getBoundingClientRect();
            const rect = innerOverlayRef.current.getBoundingClientRect();
            if (isPreview) {
                if (isPwa()) {
                    setPosition({ x: _streamRect.width / 2 - (rect.width * 2) / 2, y: _streamRect.height - rect.height - 30 })
                    return
                }
                setPosition({ x: _streamRect.width / 2 - (rect.width * 9) / 17, y: _streamRect.height - rect.height - 30 })
                return
            }
            const newPst = { x: _streamRect.width / 2 - rect.width / 2, y: _streamRect.height - rect.height - 0 }
            let x = Cookies.get('overlay_x')
            let y = Cookies.get('overlay_y')
            if (!x || !y) {
                console.log('overlay no cookie', newPst, rect);
                setPosition(newPst)
            } else {
                console.log('overlay impl cookie');

                setPosition({ x: parseInt(x), y: parseInt(y) })
            }
        }
    }, [innerOverlayRef])

    useEffect(() => {
        setOverlayBetId(bet.id)
    }, [])

    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    return <div ref={innerOverlayRef} className="overlayTrendingBet betAnimation"><div id={"betid~" + bet.id} key={"betid~" + bet.id}>
        <div className='overlayBetTitle'>
            <div>🔥</div>
            <div className='overlayBetTitleText' style={{ paddingRight: 10 }}> {bet.title}</div>

            <div className="overlayBetInfo" >
                <div>{bet.isRefunded ? 'Refunded' : 'Resolved'}</div>
                <div style={{ color: '#eee' }}>${Math.ceil(bet.optionTVL.reduce((acc, num) => acc + num, 0))} wagered</div>
            </div>
        </div>
        {
            <div className="overlayBetBody">
                {
                    bet.options.map(
                        (option, optionIndex) => {
                            return <div
                                className={'overlayBetOption'}
                                style={{
                                    userSelect: 'none',
                                    color: 'white',
                                    backgroundColor: bet.resolvedOption === optionIndex ? 'green' : 'red',
                                    cursor: 'default'
                                }}

                                key={optionIndex}>
                                <div className='overlayBetOptionText'>{option}</div>
                                <div className='overlayBetOptionSub'>
                                    {bet.resolvedOption === optionIndex ? 'Correct' : 'Wrong'}
                                </div>
                            </div>
                        }
                    )
                }
            </div>
        }
    </div></div>
}
