import { useState } from "react";
import { toast } from "react-toastify";
import { HttpClient } from "../network/index";
import { isUserLoggedIn } from "../utils/login";
export function ClaimPage(props: { login: Function, setUserCoins: any }) {
    const [telegramHandle, setTelegramHandle] = useState('')
    const handler = async () => {
        console.log('claiming');
        if (!isUserLoggedIn()) {
            props.login()
            return
        }
        try {
            let res: any = await HttpClient.post('/user/updateClaimedUSDC', { telegramHandle })
            console.log('claimingSucc', res.data);
            props.setUserCoins((coin: number) => {
                return res.data.coins
            })
            toast.success('USD successfully claimed')
        } catch (e: any) {
            console.log('claiminger', e);

            toast.error(e.response?.data?.errorMessage ?? "Failed to claim.");
        }
    }
    return <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
        <div style={{ width: '200', fontSize: '20px', display: 'flex', flexDirection: 'column', gap: 20 }}>
            <div>Your Discord Handle</div>
            <input style={{ height: '2rem', padding: '5px 15px', marginBottom: '20px', fontSize: 20 }} placeholder="@socialfi_panda" value={telegramHandle} onChange={(e) => {
                setTelegramHandle(e.target.value)
            }} />
            <button className="btn login-btn" style={{ justifyContent: 'center' }} onClick={handler}>Claim 5 USD</button>
        </div>
    </div>
}

