import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { useRecoilState } from "recoil"
import { login } from "../App"
import { Stream, StreamCreationMode } from "../model/types"
import { HttpClient } from "../network"
import { CreateBetOrStreamResponse, Response, RoomsReponseV2, SelectedChannel } from "../network/types"
import { userCoinState } from "../state"
import { isUserLoggedIn } from "../utils/login"
import { formatTimestamp, getExternalStreamLink } from "../utils/stream"
import { getFormattedDate, getFormattedTime } from "../utils/time"
import { trackLog } from "../utils/utils"
import './landingPage.css'
import { OverlayBetContainer } from "./overrlayBet"
import { StreamFramePreview } from "./streamFrame"
import { getBetFromBetResponse, getStreamId, getStreamType } from "./streamView"
type TimeZone = 'local' | 'utc'


export function LandingPage() {
  const [streamCreationMode, setStreamCreationMode] = useState<StreamCreationMode>(StreamCreationMode.CLOSED);
  const [liveStreams, setLiveStreams] = useState<Stream[]>([]);
  const [liveStreamFetched, setLiveStreamFetched] = useState(false)
  const [futureStreams, setFutureStreams] = useState<Stream[]>([]);
  const [pastStreams, setPastStreams] = useState<Stream[]>([]);
  const [isShowingPastStream, setIsShowingPastStream] = useState(false);
  const createStreamContainerRef = useRef<HTMLDivElement>(null);
  const [inCreateInAppStreamTitle, setInCreateInAppStreamTitle] = useState<string>("");
  const [inCreateFeaturedStreamUrl, setInCreateFeaturedStreamUrl] = useState<string>("");
  const [inCreateFeaturedStreamTitle, setInCreateFeaturedStreamTitle] = useState<string>("");
  const [inCreateFeaturedStreamYear, setInCreateFeaturedStreamYear] = useState<number>(2024);
  const [inCreateFeaturedStreamMonth, setInCreateFeaturedStreamMonth] = useState<number>(1);
  const [inCreateFeaturedStreamDay, setInCreateFeaturedStreamDay] = useState<number>(1);
  const [inCreateFeaturedStreamHour, setInCreateFeaturedStreamHour] = useState<number>(1);
  const [inCreateFeaturedStreamMinute, setInCreateFeaturedStreamMinute] = useState<number>(1);
  const [timeZone, setTimeZone] = useState<TimeZone>('local' as TimeZone);
  const [userCoins, setUserCoins] = useRecoilState(userCoinState)
  const [streamCreationCost, setStreamCreationCost] = useState<number>(0);
  const [isShowingTutorialPopup, setIsShowingTutorialPopup] = useState(false);
  const [betStreamCreatorOrCommentatorTakeRate, setBetStreamCreatorOrCommentatorTakeRate] = useState<number>(0);

  const navigate = useNavigate()
  const abortingCreatingStream = () => {
    setStreamCreationMode(StreamCreationMode.CLOSED)
  }

  useEffect(() => {
    if (streamCreationMode === StreamCreationMode.CLOSED) {
      setInCreateInAppStreamTitle("")
      setInCreateFeaturedStreamUrl("")
      setInCreateFeaturedStreamTitle("")
    } else if (streamCreationMode === StreamCreationMode.FUTURE) {
      const now = Date.now()
      setInCreateFeaturedStreamYear(new Date(now).getFullYear())
      setInCreateFeaturedStreamMonth(new Date(now).getMonth() + 1)
      setInCreateFeaturedStreamDay(new Date(now).getDate())
      setInCreateFeaturedStreamHour(new Date(now).getHours())
      setInCreateFeaturedStreamMinute(new Date(now).getMinutes())
    }
  }, [streamCreationMode]);


  const createStreamOnBackend = async () => {
    if (userCoins < streamCreationCost) {
      toast.error("Need " + streamCreationCost + " USD to create stream. Top up your account.")
      return
    }

    let inCreateStreamerUserName
    let inCreateChannelName
    let streamStartTime = 0
    let streamTitle
    if (streamCreationMode === StreamCreationMode.FUTURE) {
      if (!inCreateFeaturedStreamTitle || inCreateFeaturedStreamTitle.length === 0) {
        toast.error("Stream title can't be empty")
        return
      }

      try {
        if (timeZone === 'utc') {
          streamStartTime = new Date(Date.UTC(inCreateFeaturedStreamYear, inCreateFeaturedStreamMonth - 1, inCreateFeaturedStreamDay,
            inCreateFeaturedStreamHour, inCreateFeaturedStreamMinute)).getTime()
        } else {
          const localStreamStartDate = new Date(inCreateFeaturedStreamYear, inCreateFeaturedStreamMonth, inCreateFeaturedStreamDay, inCreateFeaturedStreamHour, inCreateFeaturedStreamMinute)
          const y = localStreamStartDate.getUTCFullYear()
          const m = localStreamStartDate.getUTCMonth()
          const d = localStreamStartDate.getUTCDate()
          const h = localStreamStartDate.getUTCHours()
          const min = localStreamStartDate.getUTCMinutes()
          streamStartTime = new Date(Date.UTC(y, m - 1, d, h, min)).getTime()
        }

        if (streamStartTime < Date.now()) {
          toast.error("Stream start time can only be in the future time")
          return
        }
      } catch (e: any) {
        toast.error("Invalid start time: " + e.message)
        return
      }

      streamTitle = inCreateFeaturedStreamTitle
      inCreateStreamerUserName = streamTitle.replaceAll(" ", "")
      inCreateChannelName = SelectedChannel.FEATURED
    } else if (streamCreationMode === StreamCreationMode.EXTERNAL_LINK) {
      if (!inCreateFeaturedStreamUrl || inCreateFeaturedStreamUrl.length === 0) {
        toast.error("Stream link can't be empty")
        return
      }

      const streamLink = getExternalStreamLink(inCreateFeaturedStreamUrl, toast)
      if (!streamLink.inCreateChannelName || !streamLink.inCreateStreamerUserName) {
        return
      }

      inCreateStreamerUserName = streamLink.inCreateStreamerUserName
      inCreateChannelName = streamLink.inCreateChannelName
    } else if (streamCreationMode === StreamCreationMode.IN_APP) {
      if (!inCreateInAppStreamTitle || inCreateInAppStreamTitle.length === 0) {
        toast.error("Stream title can't be empty")
        return
      }

      streamTitle = inCreateInAppStreamTitle
      inCreateStreamerUserName = streamTitle.replaceAll(" ", "")
      inCreateChannelName = SelectedChannel.IN_APP
    }

    await HttpClient.post<Response<CreateBetOrStreamResponse>>('room/create', {
      streamerUserName: inCreateStreamerUserName,
      channelName: inCreateChannelName,
      userCoins: userCoins,
      streamStartTime: streamStartTime,
      streamTitle: streamTitle
    })
      .then((response) => {
        const data = response.data

        setStreamCreationMode(StreamCreationMode.CLOSED)
        setUserCoins(data.userCoins)
        navigate("/stream/" + data.roomId)
      })
      .catch((e) => {
        console.log("!!!!create room", { e });
        toast.error("Failed to create room. Try again. (Reason: " + e.message + '"');
      })
  }


  const getRooms = async () => {
    trackLog('getRooms called')
    await HttpClient.post<Response<RoomsReponseV2>>('rooms', { "apiVersion": 2 })
      .then((response) => {
        setLiveStreamFetched(true)
        const data = response.data
        if (data.rooms) {
          const liveStreams = []
          const futureStreams = []

          for (const room of data.rooms) {
            const isFeaturedStream = room.streamStartTime && room.streamStartTime > 0
            if (!room.isStreamOver && (!isFeaturedStream || (room.newStreamId && room.newStreamId !== ""))) {
              liveStreams.push(room)
            } else {
              futureStreams.push(room)
            }
          }

          futureStreams.sort((streamA, streamB) => {
            if (streamA.streamStartTime != streamB.streamStartTime) {
              return streamA.streamStartTime! - streamB.streamStartTime!
            }

            return streamA.roomId.localeCompare(streamB.roomId)
          })

          setLiveStreams(liveStreams.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              isStreamingNatively: room.isStreamingNatively,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              streamPreviewThumbnail: room.streamPreviewThumbnail,
              trendingBet: room.trendingBet ? getBetFromBetResponse(room.trendingBet) : undefined
            } as Stream
          }))

          setFutureStreams(futureStreams.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              streamPreviewThumbnail: room.streamPreviewThumbnail,
              trendingBet: room.trendingBet ? getBetFromBetResponse(room.trendingBet) : undefined
            } as Stream
          }))
        }

        if (data.pastRooms) {
          setPastStreams(data.pastRooms.map((room) => {
            return {
              id: room.roomId,
              isStreamOver: room.isStreamOver,
              bettedTVL: room.bettedTVL,
              newStreamId: room.newStreamId,
              streamStartTime: room.streamStartTime,
              streamTitle: room.streamTitle,
              commentatorId: room.commentatorId,
              streamerProfilePicUrl: room.streamerProfilePicUrl,
              streamerDisplayName: room.streamerDisplayName,
              isStreamingNatively: room.isStreamingNatively,
              streamPreviewThumbnail: room.streamPreviewThumbnail,
              trendingBet: room.trendingBet ? getBetFromBetResponse(room.trendingBet) : undefined
            } as Stream
          }))
        }
      })
      .catch((e) => {
        console.log("!!!!getRooms failed", { e });
        toast.error("Failed to fetch streams. Try again (Reason: " + e.message + '"');
      })
  }
  useEffect(() => {
    getRooms()
    const reloadRooms = setInterval(() => {
      getRooms()
    }, 15_1000)
    return () => {
      clearInterval(reloadRooms);
    };
  }, [])


  const createStream = () => {
    if (!isUserLoggedIn()) {
      login()
      return
    }

    setStreamCreationMode(StreamCreationMode.EXTERNAL_LINK)
  }

  return <div className="landingViewContainer">
    {
      (streamCreationMode !== StreamCreationMode.CLOSED) && (
        <>
          <div className="overlay" onClick={abortingCreatingStream} />
          <div className={(streamCreationMode === StreamCreationMode.FUTURE) ? "createFeaturedStreamContainer popup" : "createStreamContainer popup"} ref={createStreamContainerRef}>
            <div className="createStreamHeader">
              <img onClick={abortingCreatingStream} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
            </div>
            <div className="createStreamHeaderTab">
              {/* <div className={(streamCreationMode === StreamCreationMode.IN_APP) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                  onClick={() => { setStreamCreationMode(StreamCreationMode.IN_APP) }}>In App Stream</div> */}
              <div className={(streamCreationMode === StreamCreationMode.EXTERNAL_LINK) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                onClick={() => { setStreamCreationMode(StreamCreationMode.EXTERNAL_LINK) }}>External Stream</div>
              <div className={(streamCreationMode === StreamCreationMode.FUTURE) ? "createStreamHeaderTabSelectedItem" : "createStreamHeaderTabItem"}
                onClick={() => { setStreamCreationMode(StreamCreationMode.FUTURE) }}>Future Stream</div>
            </div>
            {
              (streamCreationMode === StreamCreationMode.IN_APP) && (
                <>
                  <div className="streamUserNameContainer">
                    <div>Enter Stream Title</div>
                    <input value={inCreateInAppStreamTitle} onChange={e => setInCreateInAppStreamTitle(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="The ThreadGuy Interview"></input>
                  </div>
                </>
              )
            }
            {
              (streamCreationMode === StreamCreationMode.FUTURE) && (
                <>
                  <div>Enter Stream Title</div>
                  <input value={inCreateFeaturedStreamTitle} onChange={e => setInCreateFeaturedStreamTitle(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="Jerome Powell Speech"></input>
                  <div className="inCreateStreamStartTimeContainer">
                    <div className="inCreateStreamStartTimeItem">
                      <div>Year</div>
                      <input value={inCreateFeaturedStreamYear} onChange={e => setInCreateFeaturedStreamYear(parseInt(e.target.value))} className="inCreateStreamerYearInput" placeholder="Year"></input>
                    </div>
                    <div className="inCreateStreamStartTimeItem">
                      <div>Month</div>
                      <input value={inCreateFeaturedStreamMonth} onChange={e => setInCreateFeaturedStreamMonth(parseInt(e.target.value))} className="inCreateStreamerMonthInput" placeholder="Month"></input>
                    </div>
                    <div className="inCreateStreamStartTimeItem">
                      <div>Day</div>
                      <input value={inCreateFeaturedStreamDay} onChange={e => setInCreateFeaturedStreamDay(parseInt(e.target.value))} className="inCreateStreamerDayInput" placeholder="Day"></input>
                    </div>
                    <div className="inCreateStreamStartTimeItem">
                      <div>Hour</div>
                      <input value={inCreateFeaturedStreamHour} onChange={e => setInCreateFeaturedStreamHour(parseInt(e.target.value))} className="inCreateStreamerHourInput" placeholder="Hour"></input>
                    </div>
                    <div className="inCreateStreamStartTimeItem">
                      <div>Minute</div>
                      <input value={inCreateFeaturedStreamMinute} onChange={e => setInCreateFeaturedStreamMinute(parseInt(e.target.value))} className="inCreateStreamerMinuteInput" placeholder="Minute"></input>
                    </div>
                  </div>
                  <div className="selectTime">
                    <span>In: </span>
                    <label>
                      <input type="radio" name="timeZone" value="local" checked={timeZone === 'local'} onChange={e => setTimeZone(e.target.value as TimeZone)} />
                      Local Time
                    </label>
                    <label>
                      <input type="radio" name="timeZone" value="utc" checked={timeZone === 'utc'} onChange={e => setTimeZone(e.target.value as TimeZone)} />
                      UTC Time
                    </label>
                  </div>
                </>
              )
            }
            {
              (streamCreationMode === StreamCreationMode.EXTERNAL_LINK) && (
                <>
                  <div className="streamUserNameContainer">
                    <div>Enter Stream Link</div>
                    <div className="streamPlatformHint">(supports Youtube, Twitch, Twitter, Kick and streamed.su streams)</div>
                    <input value={inCreateFeaturedStreamUrl} onChange={e => setInCreateFeaturedStreamUrl(e.target.value)} className="inCreateStreamerUserNameInput" placeholder="https://www.youtube.com/watch?v=Z8UTqxU3Cdo"></input>
                  </div>
                </>
              )
            }
            <div className="createStreamFooter">
              <button className="btn createStreamBtn" onClick={createStreamOnBackend}>Create Stream</button>
              <div className="streamFeesHint">* {(streamCreationCost === 0) ? "Free to create. " : `Costs ${streamCreationCost} USD. `}Earn up to {betStreamCreatorOrCommentatorTakeRate * 100}% bet fees of this stream.</div>
            </div>
          </div>
        </>
      )
    }
    {
      isShowingTutorialPopup && (
        <>
          <div className="overlay" onClick={() => setIsShowingTutorialPopup(false)} />
          <div className="tutorialContainer">
            <div className="createStreamHeader">
              <img onClick={() => setIsShowingTutorialPopup(false)} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
            </div>
            <div className="tutorialHowToPlay">
              How it Works
            </div>
            {/* <div className="tutorialContentHeader">
                Bet 
              </div> */}
            <div className="tutorialContentBody">
              <div>1. Create or place bets on livestreams.</div>
              <div>2. When you bet, you earn tickets. Lower odds = more tickets.</div>
              <div>3. Payout = Total Wadgered of the Bet × Your Tickets on Winning Option / Total Winning Tickets.</div>
              <div>4. The team resolves all bets.</div>
              <div>5. Deposit or withdraw anytime on the "Deposit" page.</div>
            </div>
          </div>
        </>
      )
    }

    {
      !liveStreams.length && liveStreamFetched && <div className="streamsSection">
        <div className="discord-cta-container">
          <div className="discord-cta-text">Get notified for the next stream!</div>
          <a href="https://discord.gg/kFEbEevKNf" rel="noreferrer" target="_blank" className="discord-join-button">
            <svg className="discord-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
              <path fill="#5865F2" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z" />
            </svg>
            Join Discord
          </a>
        </div>

      </div>
    }
    <div style={{ position: 'relative' }}>
      <div onClick={createStream} style={{ display: 'flex', alignItems: 'center', gap: 2, position: 'absolute', top: 0, right: 10, cursor: 'pointer' }}>
        <img className="addStreamIcon" src="/addIcon.svg" />
        <div className="addStreamText">Add Stream</div>
      </div>
    </div>

    {
      (liveStreams.length > 0) && (
        <div className="streamsSection">
          <div className="streamsSectionHeader">
            <div className="addStreamContainerText">
              Live Streams
            </div>
          </div>
          <div className="streamsGallery">
            {
              liveStreams.map((stream) => (
                <StreamPreview stream={stream} key={stream.id} />
              ))
            }
          </div>
        </div>
      )
    }

    {
      !liveStreams.length && futureStreams.length > 0 && <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }} className={liveStreams.length === 0 ? "mostRecentStreamHeader liveStreamHeader" : "mostRecentStreamHeader"}>
          <div className="streamsSectionHeader">
            Future Streams
          </div>
        </div>
        <div className="futureStreamsContainer">
          {
            (futureStreams.length > 0) && (
              futureStreams.map((stream, index) => (
                <div className="futureStreamContainer">
                  <div className="futureStreamDateHeader">
                    {getStreamDateIfNecessary(futureStreams, index - 1, index)}
                  </div>
                  <div className="futureStreamContentContainer" onClick={() => {
                    navigate("/stream/" + encodeURIComponent(stream.id) + window.location.search)
                    // window.location.href = '/stream/' + stream.id + window.location.search 
                  }}>
                    <div className="futureStreamContent">
                      <div className="futureStreamContentTitle">{stream.streamTitle}</div>
                      <div className="futureStreamContentTime">{getFormattedTime(stream.streamStartTime!)} (Local Time)</div>
                    </div>
                    <div className="futureStreamContentCaret">{">"}</div>
                  </div>
                </div>
              )
              )
            )
          }
        </div>
      </>
    }

    {
      (!liveStreams.length && pastStreams.length > 0) && (
        <div className="streamsSection">
          <div className="streamsSectionHeader">
            <div className="addStreamContainerText">
              Past Streams
            </div>
          </div>
          {
            <div className="streamsGallery">
              {
                pastStreams.map((stream) => (
                  <StreamPreview stream={stream} key={stream.id} />
                ))
              }
            </div>
          }
        </div>
      )
    }
  </div>
}



function StreamPreview({ stream }: {
  stream: Stream
}) {
  const navigate = useNavigate()
  const streamContainerRef = useRef<HTMLDivElement | null>(null)
  const [streamCountDowns, setStreamCountdowns] = useState<{ [key: string]: number }>({});

  if (stream?.trendingBet && !stream?.trendingBet?.options) {
    // @ts-ignore
    stream.trendingBet.options = stream.trendingBet.optionDisplayNames
  }
  return <div ref={streamContainerRef} className="previewStreamContainer" key={stream.id} onClick={() => {
    navigate("/stream/" + encodeURIComponent(stream.id) + window.location.search)
    // window.location.href = '/stream/' + stream.id + window.location.search 
  }}>
    {
      stream.trendingBet && <OverlayBetContainer streamContainerRef={streamContainerRef} trendingBet={stream.trendingBet} newBets={[]} allBets={[]} isPrview={true} />
    }
    <div style={{ width: '100%', height: '100%', position: 'absolute', zIndex: 999 }}></div>
    {
      getStreamType(getStreamId(stream)) === SelectedChannel.FEATURED ? (
        <div className="previewStreamPlayer featuredPreviewStreamPlayer">
          {
            (stream.streamTitle && stream.streamTitle !== "") && (
              <div className="countdownTextContainer">{stream.streamTitle}</div>
            )
          }
          {
            getStreamId(stream) && (
              <div className="streamCountdownText">
                {formatTimestamp(streamCountDowns[getStreamId(stream)!])}
              </div>
            )
          }
        </div>
      ) : <StreamFramePreview stream={stream} />

    }
    {
      stream.isStreamOver && !stream.isStreamingNatively && (
        <div className="streamPreviewLivenessOverlay">
          Offline
        </div>
      )
    }
    <div className="streamPreviewFooter">
      <div>Total Wagered:</div>
      <img className="streamPreviewCoinIcon" src="/coin.png"></img>
      <div>{stream.bettedTVL}</div>
    </div>
  </div>
}

const getStreamDateIfNecessary = (streamArray: Stream[], prevIndex: number, currentIndex: number): string | undefined => {
  const timezoneOffset = new Date().getTimezoneOffset()
  const currentStreamStartTime = streamArray[currentIndex].streamStartTime ?
    new Date(streamArray[currentIndex].streamStartTime! + timezoneOffset) : new Date()
  if (prevIndex < 0) {
    return getFormattedDate(currentStreamStartTime)
  }

  const prevStreamStartTime = streamArray[prevIndex].streamStartTime ?
    new Date(streamArray[prevIndex].streamStartTime! + timezoneOffset) : new Date()

  if (prevStreamStartTime.getFullYear() === currentStreamStartTime.getFullYear() &&
    prevStreamStartTime.getMonth() === currentStreamStartTime.getMonth() &&
    prevStreamStartTime.getDate() === currentStreamStartTime.getDate()) {
    return undefined
  }

  return getFormattedDate(currentStreamStartTime)
}