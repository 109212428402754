import './bullet.css'
import { ChatMessage, MessageBodyType } from '../network/types'
import { getMessageIcon } from './streamView'
import { useEffect } from 'react'
import { isOnMobile, trackLog } from '../utils/utils'
export type bulletMessage = ChatMessage & {
    createdAt: number
    floor: number
    withPadding: boolean
}
export let maxFloor = 8;
const maxFloorMobile = 2;
const giftZoomSize = '140%'
const streamerHeght = 5; // how many floor the streamer cover
export function BulletCanvas(props: { messages: bulletMessage[] }) {
    useEffect(() => {
        if (isOnMobile()) {
            maxFloor = maxFloorMobile
        }
    }, [])
    let remBase = props.messages.length ? Math.max(...props.messages.map(b => b.floor)) : undefined
    let height = (remBase != undefined) ? (remBase * 2 + 6) + 'rem' : 0
    return <div className="bulletCanvas">
        <div className='bulletMessagesContainer' style={{ height }}>
            {props.messages.map(message => {
                let floorClassName = 'floor' + message.floor
                let animation = 'bulletMove 20s linear forwards'
                if (message.withPadding && message.floor < streamerHeght) {
                    animation = 'bulletMoveWithPadding 20s linear forwards'
                }
                return <div className={'bulletMessageContainer ' + floorClassName} key={message.messageId} style={{
                    animation,
                    zoom: message.type == MessageBodyType.SEND_GIFT ? giftZoomSize : 'normal',
                }}>
                    {getMessageIcon(message)}
                    <div className='bulletMessageText' style={{ color: (message.color ? message.color : "#ffffff") }}>{message.text}</div>
                </div>
            })}
        </div>
    </div>
}
