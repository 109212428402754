import { useEffect, useState } from "react"
import { isOnMobile, trackLog } from "../utils/utils"

export function InStreamEarning(props: { userUsdEarnings: number | undefined }) {
    const [inc, setInc] = useState(0)
    const [prev, setPrev] = useState(0)
    const [times, setTimes] = useState(0)
    const [popClass, setPopClass] = useState<'iecp_green' | 'iecp_red'>('iecp_green')
    const fontSize = isOnMobile() ? '1rem' : '1.1rem'
    useEffect(() => {
        if (typeof props.userUsdEarnings != 'undefined') {
            trackLog('set pops', props.userUsdEarnings, inc, prev, times)
            setPrev(props.userUsdEarnings)
            let change = props.userUsdEarnings - prev
            setTimes(times + 1)
            setInc(change)
            if (change > 0) {
                trackLog('set green')
                setPopClass('iecp_green')
            } else {
                trackLog('set red')
                setPopClass('iecp_red')
            }
            const timer = setTimeout(() => {
                setInc(0);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [props.userUsdEarnings])
    let earningStr = ''
    let earningColor = '#1fff20'
    if (typeof props.userUsdEarnings == 'undefined' || props.userUsdEarnings === 0) {
        earningStr = '0.00'
        earningColor = '#ccc'
    } else if (props.userUsdEarnings > 0) {
        earningStr = '$' + props.userUsdEarnings.toFixed(2)
    } else {
        earningStr = `-$${Math.abs(props.userUsdEarnings).toFixed(2)}`
        earningColor = 'red'
    }
    return <div className="inStreamEarningContainer">
        {times > 1 && inc != 0 && <div className={"inStreamEarningContainerPop " + popClass} key={prev} style={{ fontSize }}>
            ${inc > 0 && '+'}{inc.toFixed(2)}
        </div>}
        <div className="inStreamEarningText">My Earnings</div>
        <div style={{ color: earningColor, fontWeight: 'bold' }}>{earningStr}</div>
    </div>
}