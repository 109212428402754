import { PublicKey } from '@solana/web3.js'
import { useEffect, useRef, useState } from "react"
import { toast } from "react-toastify"
import { useRecoilState, useRecoilValue } from 'recoil'
import { HttpClient } from "../network"
import { defaultBetState, userState } from '../state'
import { trackLog } from "../utils/utils"

export function SettingsPage({ loggedIn, userCoins, userPaypalId, setIsStripePopup, setIsShowingDisplayNamePopup, logout, setUserCoins, setIsWidthDrawPopup }: {
    loggedIn: boolean
    userCoins: number
    setIsStripePopup: Function
    setIsShowingDisplayNamePopup: Function
    logout: Function
    setUserCoins: Function
    userPaypalId: string
    setIsWidthDrawPopup: Function
    withdrawSolanaAddress: string
}) {
    const user = useRecoilValue(userState)
    const [defaultBet, setDefaultBet] = useRecoilState(defaultBetState)
    const [isShowingDefaultBetForm, setIsShowingDefaultBetForm] = useState(false)
    const [updateeDefaultBet, setUpdateeDefaultBet] = useState('n')
    const [isUpdating, setIsUpdating] = useState(false)
    return <>
        {
            isShowingDefaultBetForm && (
                <>
                    <div className="overlay" onClick={() => setIsShowingDefaultBetForm(false)} />
                    <div className="popup">
                        <div className="createStreamHeader">
                            <div style={{ flex: 1, textAlign: 'center' }}>Default Bet Size</div>
                            <img onClick={() => {
                                setIsShowingDefaultBetForm(false)
                            }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
                        </div>

                        <div style={{ display: 'flex', boxSizing: 'border-box', width: '220px', gap: 10, flexDirection: 'column', alignSelf: 'center', marginTop: 12 }}>
                            <div style={{ position: 'relative' }}>
                                <input
                                    autoFocus
                                    placeholder="1"
                                    value={updateeDefaultBet === 'n' ? defaultBet.toString() : updateeDefaultBet}
                                    onChange={(e) => {
                                        setUpdateeDefaultBet(e.target.value)
                                    }}
                                    style={{ width: '218px', boxSizing: 'border-box', padding: '0.7rem 50px 0.7rem 24px', height: '2.55rem', fontSize: '1.2rem' }}
                                />
                                <div style={{ color: '#999', position: 'absolute', left: 10, top: 8 }}>$</div>
                            </div>
                            <button className="btn" onClick={async () => {
                                if (isUpdating) {
                                    return
                                }
                                setIsUpdating(true)
                                try {
                                    console.log('updateres send', updateeDefaultBet);

                                    let res: any = await HttpClient.post('user/update_default_bet_size', { defaultBetSize: parseInt(updateeDefaultBet) })
                                    console.log('updateres res 11', res);
                                    setDefaultBet(parseInt(updateeDefaultBet))
                                    setIsShowingDefaultBetForm(false)
                                    toast.success('Updated Success!')
                                } catch (e: any) {
                                    console.log('updateres e', e);
                                    toast.error(e.response.data.errorMessage)
                                }
                                console.log('updateres res22 ')
                                setIsUpdating(false)

                            }}>{isUpdating ? <div className='spinnerNano'></div> : <span>Confirm</span>}</button>
                        </div>

                    </div>
                </>
            )
        }

        {
            loggedIn ? (
                <div className="walletContainer">
                    <div className="walletItemContainer">
                        <img className="walletPagePfpImage" src="./coin.png" alt="Coin" />
                        <div>{Math.round(userCoins * 100) / 100}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 40, paddingBottom: 20, gap: 20 }}>
                        <div >
                            <button className="btn" onClick={() => {
                                setIsStripePopup(true)
                            }}>Top Up</button>
                        </div>

                        <div >
                            <button className="btn-dark" onClick={() => {
                                setIsWidthDrawPopup(true)
                            }}>Withdraw</button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 40, paddingBottom: 20, gap: 20 }}>
                        <div>Default bet size: <b>${defaultBet}</b> (<span style={{ textDecoration: 'underline', color: '#ccc', cursor: 'pointer' }} onClick={(e) => {
                            setIsShowingDefaultBetForm(true)
                        }}>Update</span>)
                        </div>
                    </div>
                    <div className="walletItemContainer">
                        <img className="walletPagePfpImage" src={user.pic} alt="Profile Picture" />
                        <div className="walletPageDisplayName">{user.name}</div>
                        <div className="logoutContainer" onClick={() => setIsShowingDisplayNamePopup(true)}>
                            <img className="editDisplayNameButton" src="/edit_button.png"></img>
                        </div>
                    </div>
                    <div className="walletItemContainer">
                        <div className="logoutContainer" onClick={() => logout()}>
                            <div className="logoutText">Log Out</div>
                        </div>
                    </div>
                </div >
            ) : (
                <div className="walletContainer">You need to log in first</div>
            )
        }
    </>
}

export function WidhDraw({ setIsWidthDrawPopup, setUserCoins, userPaypalId, userCoins, withdrawSolanaAddress, setUserPaypalId, setWithdrawSolanaAddress }:
    {
        setIsWidthDrawPopup: Function,
        setUserCoins: Function,
        userPaypalId: string,
        userCoins: number,
        setWithdrawSolanaAddress: Function
        setUserPaypalId: Function
        withdrawSolanaAddress: string
    }
) {
    const [paypalId, setPaypalId] = useState(userPaypalId);
    const [inputValue, setInputValue] = useState('');
    const [isSending, setIsSending] = useState(false)
    const [successMsg, setSuccessMsg] = useState('')
    const inputRef = useRef<HTMLInputElement | null>(null);
    const inputRef_paypal = useRef<HTMLInputElement | null>(null);
    const inputRef_solana = useRef<HTMLInputElement | null>(null);
    const [step, setStep] = useState(1)
    const [type, setType] = useState<'paypal' | 'solana'>('paypal')
    const [solanaAddress, setSolanaAddress] = useState(withdrawSolanaAddress)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleChange = (e: any) => {
        const value = e.target.value;
        if (/^\d*\.?\d*$/.test(value)) {
            setInputValue(value);
        }
    };
    let btnStyle: any = { justifyContent: 'center', boxSizing: 'border-box', width: '100%', height: '2.55rem', fontSize: '1.2rem', marginTop: 5 }
    if (inputValue === '') {
        btnStyle.backgroundColor = '#3F3F46'
        btnStyle.color = '#71717A'
    }
    if (step === 1) {
        return <>
            <div className="createStreamHeader" style={{ marginBottom: '10px' }}>
                <div style={{ flex: 1, textAlign: 'center' }}>Withdraw</div>
                <img onClick={() => {
                    setIsWidthDrawPopup(false)
                }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
            </div>
            <div style={{ maxWidth: '220px', margin: '0 auto', display: 'flex', flexDirection: 'column', gap: 16 }}>
                <div style={{ position: 'relative' }}>
                    <input style={{ width: '126px', paddingLeft: 24, paddingRight: 50 }} ref={inputRef} className="input" onChange={handleChange} value={inputValue} placeholder="100" />
                    <div style={{ color: '#999', position: 'absolute', left: 10, top: 22 }}>$</div>
                    <div style={{ color: '#999', cursor: 'pointer', position: 'absolute', right: 15, top: 25, textDecoration: 'underline', fontSize: 15 }} onClick={() => {
                        setInputValue((Math.floor(userCoins * 100) / 100).toFixed(2))
                    }}>max</div>
                </div>
                <div >
                    <button style={btnStyle} className="btn" onClick={async () => {
                        if (!inputValue) {
                            return
                        }
                        setStep(2)
                        if (!userPaypalId) {
                            setTimeout(() => {
                                if (inputRef_paypal.current) {
                                    inputRef_paypal.current.focus()
                                }
                            }, 50);
                        }
                    }}>Next</button></div>
            </div>
        </>
    }

    return <>
        <div className="createStreamHeader" style={{ marginBottom: '10px' }}>
            {step === 2 && <div style={{ cursor: 'pointer' }} onClick={() => {
                setStep(1)
                setSuccessMsg('')
            }}><img alt="back" src="/back-button.svg" style={{ width: 20, height: 20 }} /></div>}
            <div style={{ flex: 1, textAlign: 'center' }}>Withdraw</div>
            <img onClick={() => {
                setIsWidthDrawPopup(false)
            }} className="createStreamCloseIcon" src="/close.svg" alt="Close"></img>
        </div>
        {successMsg ? <div style={{ padding: 10, textAlign: 'center' }}>
            <img src="/success_checkmark.png" style={{ width: 80, height: 80 }} />
            <div style={{ paddingTop: 15 }}>{successMsg}</div>
        </div> :
            <div style={{ width: '280px', margin: '0 auto', display: 'flex', flexDirection: 'column', gap: 25 }}>

                <div><label onClick={() => {
                    setType('paypal')
                    if (inputRef_paypal.current && !paypalId) {
                        inputRef_paypal.current.focus()
                    }
                }}><input type="radio" name="type" value="paypal" checked={type === 'paypal'} /> Paypal</label></div>

                {type === 'paypal' &&
                    <div >
                        <div style={{ fontSize: 14 }}>Paypal Email</div>
                        <div><input ref={inputRef_paypal} style={{ width: '280px' }} className="input" onChange={(e) => setPaypalId(e.target.value)} value={paypalId} /></div>
                    </div>}

                <div><label onClick={() => {
                    setType('solana')
                    if (inputRef_solana.current && !solanaAddress) {
                        inputRef_solana.current.focus()
                    }
                }}><input type="radio" name="type" value="solana" checked={type === 'solana'} /> Solana</label></div>

                {type === 'solana' &&
                    <div >
                        <div style={{ fontSize: 14 }}>Solana Address</div>
                        <div><input placeholder='DapsTdDp53R1t1Cav5UHW6eNYHpzj1BwQoyPjG5FjGpN' ref={inputRef_solana} style={{ width: '280px' }} className="input" onChange={(e) => setSolanaAddress(e.target.value)} value={solanaAddress} /></div>
                    </div>}

                <div>
                    <button style={{ height: '2.55rem', width: '293px', justifyContent: 'center' }} className="btn" onClick={async () => {
                        if (isSending) {
                            return
                        }
                        if (!inputValue) {
                            toast.error('no amount')
                            return
                        }
                        let recipient
                        if (type === 'paypal') {
                            if (!isValidEmail(paypalId)) {
                                toast.error('not a valid email address')
                                return
                            }
                            setUserPaypalId(paypalId)
                            recipient = paypalId
                        } else {
                            if (!solanaAddress) {
                                toast.error('Empty solana address')
                                return
                            }
                            if (!isValidSolanaAddress(solanaAddress)) {
                                toast.error('not a valid solana address')
                                return
                            }
                            setWithdrawSolanaAddress(solanaAddress)
                            recipient = solanaAddress
                        }
                        setIsSending(true)
                        try {
                            let params = {
                                amount: inputValue,
                                type,
                                recipient
                            }
                            trackLog('sending params', params)
                            let res: any = await HttpClient.post('stripe/withdrawalFromPaypal', params)
                            trackLog('res:', res)
                            setSuccessMsg(res.data.message)
                            setUserCoins(res.data.coins)
                            setIsSending(false)
                        } catch (e: any) {
                            toast.error(e.response.data.errorMessage)
                            setIsSending(false)
                        }
                    }}>{isSending ? 'Confirming..' : 'Confirm'}</button></div>
            </div>
        }
    </>
}
function isValidEmail(email: string) {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
}
function isValidSolanaAddress(address: string) {
    try {
        const publicKey = new PublicKey(address);
        return publicKey.toString() === address;
    } catch (e) {
        return false;
    }
}